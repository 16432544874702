import { CartValue, ItemDetails } from '../modules/cart/cart.models';
import { CartNotificationItem } from '../shared/models/cart-notification.model';

export class AddItemToCart {
  static readonly type = '[Cart] Add Items to cart';
  constructor(public item: ItemDetails) {}
}

export class UpdateCartValue {
  static readonly type = '[Cart] Update Cart Value';
  constructor(public cartValue: CartValue) {}
}

export class IncreaseCartItem {
  static readonly type = '[Cart] Increase Cart Item';
  constructor(public quantity: number, public orderItemId: string, public showNotification = true) {}
}

export class DecreaseCartItem {
  static readonly type = '[Cart] Decrease Cart Item';
  constructor(public quantity: number, public deviceId: string, public showNotification = true) {}
}

export class DeleteDeviceItemFromCart {
  static readonly type = '[Cart] delete device from cart';

  constructor(public deviceId: string) {}
}

export class ClearCart {
  static readonly type = '[Cart] Clear items in cart';
}

export class UpdatesItemsInCart {
  static readonly type = '[Cart] Updates Items in cart';
  constructor(public item: ItemDetails) {}
}

export class ShowAddedItemsToCart {
  static readonly type = '[Cart] Show added item to Cart';
  constructor(public items: CartNotificationItem[]) {}
}

export class ClearCartNotification {
  static readonly type = '[Cart] Clear Notification Cart';
}

export class SetDisableCartItems {
  static readonly type = '[Cart] Set Disable Cart Items State';
  constructor(public disableState: boolean) {}
}

export class AddAllRecommendedDevicesToCart {
  static readonly type = '[Cart] Add all recommended devices';
}

export class BuyNowRecommendedDevices {
  static readonly type = '[Cart] Buy now recommended devices';
}
