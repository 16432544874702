import { Pipe, PipeTransform } from '@angular/core';
import { SizesAndPrices } from '../models/item';
import { getDevicePriceInCents } from '../utils/price';
import { getEverydayPay, getFirstPayCost } from '../utils/pays';

@Pipe({name: 'priceInDollars'})
export class PricePipe implements PipeTransform {
  public transform(cents: number): string {
    const price = cents ? (cents / 100).toFixed(2).replace(/.00$/, '') : 0;

    return `$${price}`;
  }
}

@Pipe({name: 'devicePrice'})
export class DevicePricePipe implements PipeTransform {
  constructor(private pricePipe: PricePipe) {
  }

  public transform(sizesAndPrices: SizesAndPrices[] = [{menuAttributes: []}] as SizesAndPrices[],
                   {quantity} = {quantity: 1}): string {
    quantity = quantity || 1;
    const cents = getDevicePriceInCents(sizesAndPrices) * quantity;

    return this.pricePipe.transform(cents);
  }
}

@Pipe({name: 'firstPay'})
export class FirstPayPipe implements PipeTransform {
  constructor(private pricePipe: PricePipe) {
  }

  public transform(sizesAndPrices: SizesAndPrices[], {quantity} = {quantity: 1}): string {
    quantity = quantity || 1;
    const cents = getDevicePriceInCents(sizesAndPrices) * quantity;
    const pay = getFirstPayCost(cents);

    return this.pricePipe.transform(pay);
  }
}

@Pipe({name: 'everyDayPay'})
export class EveryDayPayPipe implements PipeTransform {
  constructor(private pricePipe: PricePipe) {
  }

  public transform(sizesAndPrices: SizesAndPrices[], {quantity} = {quantity: 1}): string {
    quantity = quantity || 1;
    const cents = getDevicePriceInCents(sizesAndPrices) * quantity;
    const pay = getEverydayPay(cents);

    return this.pricePipe.transform(pay);
  }
}


@Pipe({name: 'totalFirstPay'})
export class TotalFirstPayPipe implements PipeTransform {
  constructor(private pricePipe: PricePipe) {
  }

  public transform(price = 0): string {
    const pay = getFirstPayCost(price);

    return this.pricePipe.transform(pay);
  }
}

@Pipe({name: 'totalEveryDayPay'})
export class TotalEveryDayPayPipe implements PipeTransform {
  constructor(private pricePipe: PricePipe) {
  }

  public transform(price = 0): string {
    const pay = getEverydayPay(price);

    return this.pricePipe.transform(pay);
  }
}
