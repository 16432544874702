import { Store } from '@ngxs/store';
import { SetSpinnerVisibility } from '../../ngxs/app.actions';

export class Spinner {
  constructor(protected store: Store) {}

  protected showSpinner(): void {
    this.store.dispatch(new SetSpinnerVisibility(true));
  }

  protected hideSpinner(): void {
    this.store.dispatch(new SetSpinnerVisibility(false));
  }
}
