<div class="rs-header d-flex align-items-center justify-content-between">
  <div class="rs-header__menu">
    <button mat-icon-button (click)="openMenu()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="rs-header__title">
    <span>{{ "titles.devices-marketplace" | translate }}</span>
  </div>
  <div class="rs-header__profile">
    <rs-menu iconClass="rs-backoffice-menu__icon">
      <div class="rs-backoffice-menu">
        <div
          class="rs-backoffice-menu__item"
          *ngFor="let backOfficeMenuItem of backOfficeMenuData"
        >
          <img
            class="rs-backoffice-menu__img"
            [src]="backOfficeMenuItem.iconUrl"
          />
          <p class="rs-backoffice-menu__title">
            {{ backOfficeMenuItem.title | translate }}
          </p>
        </div>
      </div>
    </rs-menu>

    <button class="rs-header__user-img" [matMenuTriggerFor]="toolbarUserMenu">
      <div class="rs-header__user-img--inner">
        <rs-image-or-default-icon
          [iconSRC]="'assets/icons/user.svg'"
          photo="{{ userPhoto$ | async }}"
        ></rs-image-or-default-icon>
      </div>
    </button>

    <mat-menu #toolbarUserMenu="matMenu">
      <button mat-menu-item (click)="logout()">
        <mat-icon> exit_to_app </mat-icon>
        <span>{{ "authentication.logout" | translate }}</span>
      </button>
    </mat-menu>

    <button
      *ngIf="(venues$ | async)?.length"
      mat-flat-button
      class="rs-header__venue rs-venue"
      (click)="openVenues()"
    >
      <div
        class="rs-venue__content"
        *ngIf="selectedVenue$ | async; let selectedVenue"
      >
        <rs-venue-logo size="33px" [logo]="selectedVenue.logo"></rs-venue-logo>
        <p class="rs-venue__text rs-truncated">{{ selectedVenue.name }}</p>
        <mat-icon class="rs-venue__icon">arrow_drop_down</mat-icon>
      </div>
    </button>
  </div>
</div>
