import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Authentication } from 'src/app/modules/sign-in/models/authentication.model';
import { AuthenticationData } from '../models/authentication-data.model';
import { ENDPOINTS } from '../constants/endpoint';
import { ApiService } from './api.service';
import { getEndpointForContextSwitch } from '../utils/endpoint';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private readonly apiService: ApiService) {}

  public signIn(authentication: Authentication): Observable<AuthenticationData> {
    return this.apiService.post(ENDPOINTS.loginArea.login, authentication);
  }

  public refreshToken(refreshToken: string): Observable<AuthenticationData> {
    return this.apiService.post(ENDPOINTS.loginArea.refresh, {refreshToken});
  }

  public switchContext(tenantId: string): Observable<AuthenticationData> {
    return this.apiService.post(getEndpointForContextSwitch(ENDPOINTS.loginArea.contextSwitch, tenantId));
  }
}
