import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from 'src/app/shared/services/api.service';
import { ENDPOINTS } from '../constants/endpoint';
import { Address, AddressFormValue } from '../models/address.model';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  constructor(private readonly apiService: ApiService) {}

  public getLocation(
    address: AddressFormValue
  ): Observable<Address> {
    return this.apiService.post(ENDPOINTS.geoLocation.address, address);
  }
}
