import { Component, Input } from '@angular/core';
import { Card } from 'src/app/shared/models/card.model';

@Component({
  selector: 'rs-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss'],
})
export class CardInfoComponent {
  @Input() card: Card;
}
