import { UserProfileAddress } from '../shared/models/address.model';

export class GetAddressesInitData {
  static readonly type = '[Addresses Init Data] Get';
}

export class AddAddress {
  static readonly type = '[UserProfileAddress] Add';

  constructor(public address: UserProfileAddress) {
  }
}

export class EditAddress {
  static readonly type = '[UserProfileAddress] Edit';

  constructor(public addressId: string, public address: UserProfileAddress) {
  }
}

export class DeleteAddress {
  static readonly type = '[UserProfileAddress] Delete';

  constructor(public addressId: string) {
  }
}

export class MakeAddressAsDefault {
  static readonly type = '[UserProfileAddress] Make As Default';

  constructor(public addressId: string, public address: UserProfileAddress) {
  }
}

export class OpenChooseDefaultAddressDialog {
  static readonly type = '[Choose Default Dialog] Open';
}
