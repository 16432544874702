import { Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { CheckoutService } from '../shared/services/checkout.service';
import { CartService } from '../shared/services/cart.service';
import {
  Checkout,
  ResetBillingAddressValidity,
  ResetCheckoutState,
  SetDownpaymentAmount,
  ValidateAndSaveBillingCard,
} from './checkout.actions';
import { AddCard } from './wallet.actions';
import { ClearCart } from './cart.actions';
import { Spinner } from '../shared/classes/spinner.class';
import { DIALOG_PARAMETERS } from '../shared/constants/common';
import { PAGES } from '../shared/constants/pages';
import { UserProfileState } from './user.state';
import { WalletState } from './wallet.state';
import { CartState } from './cart.state';
import {
  Address,
  AddressFormValue,
  UserProfileAddress,
} from '../shared/models/address.model';
import { CartItem } from '../modules/cart/cart.models';
import { DialogTitle } from '../shared/enums/dialog-title.enum';
import { Card, CardForm, NewCardToAdd } from '../shared/models/card.model';
import { ConfirmationDialogComponent } from '../shared/components/confirmation-dialog/confirmation-dialog.component';

interface CheckoutStateModel {
  downpaymentAmount: number;
  isBillingAddressInvalid: boolean;
}

const INIT_CHECKOUT_STATE: CheckoutStateModel = {
  downpaymentAmount: null,
  isBillingAddressInvalid: false,
};

@State<CheckoutStateModel>({
  name: 'checkout',
  defaults: { ...INIT_CHECKOUT_STATE },
})
@Injectable()
export class CheckoutState extends Spinner {
  constructor(
    private readonly checkoutService: CheckoutService,
    private readonly cartService: CartService,
    protected readonly store: Store,
    private readonly dialog: MatDialog,
    private ngZone: NgZone
  ) {
    super(store);
  }

  @Selector()
  static isBillingAddressInvalid({
    isBillingAddressInvalid,
  }: CheckoutStateModel): boolean {
    return isBillingAddressInvalid;
  }

  @Selector()
  static downpaymentAmount(state: CheckoutStateModel): number {
    return state.downpaymentAmount;
  }

  @Selector([WalletState.defaultCard, CartState.items])
  static isCheckoutDisabled(
    state: CheckoutStateModel,
    defaultCard: Card,
    cartItems: CartItem[]
  ): boolean {
    return !defaultCard || !cartItems.length;
  }

  @Action(SetDownpaymentAmount)
  setDownpaymentAmount(
    { patchState }: StateContext<CheckoutStateModel>,
    { amount }: SetDownpaymentAmount
  ) {
    patchState({
      downpaymentAmount: amount,
    });
  }

  @Action(ValidateAndSaveBillingCard)
  validateAndSaveBillingCard(
    { patchState }: StateContext<CheckoutStateModel>,
    { card }: ValidateAndSaveBillingCard
  ) {
    this.showSpinner();

    if (card.isAddressValid) {
      const data = this.getSavedCardPayload(
        card.billingAddress as UserProfileAddress,
        card
      );
      return this.store.dispatch(new AddCard(data));
    } else {
      return this.checkoutService
        .getLocation(card.billingAddress as AddressFormValue)
        .pipe(
          tap((newBillingAddress) => {
            patchState({
              isBillingAddressInvalid: false,
            });
            const data = this.getSavedCardPayload(newBillingAddress, card);

            return this.store.dispatch(new AddCard(data));
          }),
          catchError((error: HttpErrorResponse) => {
            patchState({
              isBillingAddressInvalid: true,
            });
            return throwError(error);
          })
        );
    }
  }

  @Action(ResetCheckoutState)
  resetState({ patchState }: StateContext<CheckoutStateModel>) {
    patchState({ ...INIT_CHECKOUT_STATE });
  }

  @Action(ResetBillingAddressValidity)
  resetBillingAddressValidity({
    patchState,
  }: StateContext<CheckoutStateModel>) {
    patchState({
      isBillingAddressInvalid: false,
    });
  }

  @Action(Checkout)
  checkout({ patchState, getState }: StateContext<CheckoutStateModel>) {
    this.showSpinner();
    const cartId = this.store.selectSnapshot((state) => state.cart.cartId);
    const address = this.store.selectSnapshot(UserProfileState.selectedVenueAddress);

    return this.cartService.commitCart(cartId, address).pipe(
      switchMap(( {id} ) => {
        const downpayment = getState().downpaymentAmount;
        const defaultCardTocken = this.store.selectSnapshot(
          WalletState.defaultCard
        ).id;
        return this.cartService
          .order(id, downpayment, defaultCardTocken)
          .pipe(
            tap(() => {
              this.hideSpinner();
              this.ngZone.run(() => {
                this.dialog
                  .open(
                    ConfirmationDialogComponent,
                    {
                      ...DIALOG_PARAMETERS.confirmation,
                      data: { dialogTitle: DialogTitle.ORDER_CONFIRMATION }
                    }
                  )
                  .afterClosed()
                  .subscribe(() => {
                    this.store.dispatch([
                      new ClearCart(),
                      new Navigate([PAGES.ORDERS_HYSTORY])
                    ]);
                  });
              });
            })
          );
      })
    );
  }

  private getSavedCardPayload(
    address: Address | UserProfileAddress,
    card: CardForm
  ): NewCardToAdd {
    return {
      tokenType: 'rockspoon',
      redactedCardNumber: card.cardNumber,
      cardNumber: card.cardNumber,
      cardholderName: card.cardHolder,
      name: this.store.selectSnapshot(UserProfileState.userFullName),
      cardExpirationDate: card.expirationDate,
      zipCode: address.zipcode,
      address: (address.address1 ?? '').split(',').join(''),
      countryCode: address.countryCode,
      city: address.city,
      state: address.state,
      default: card.isDefault,
      cvv: card.cvv,
    };
  }
}
