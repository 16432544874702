import { Directive, HostListener } from '@angular/core';

// exclude these keys for input with type 'number' for having only integers
const excludedKeys = new Set(['.', '-', '+', 'e']);

@Directive({
  selector: '[rsIntegerNumberOnly]'
})
export class IntegerNumberOnlyDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (excludedKeys.has(e.key)) {
      e.preventDefault();
    }
  }
}
