import { NgModule } from '@angular/core';
import { SpinnerComponent } from './spinner.component';
import { SharedModule } from '../../modules/shared.module';
import { MaterialModule } from '../../modules/material.module';

@NgModule({
  declarations: [SpinnerComponent],
  imports: [SharedModule, MaterialModule],
  exports: [SpinnerComponent],
})
export class SpinnerModule {}
