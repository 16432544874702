import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import {
  CalculatorData,
  RecommendedDevice,
} from '../../modules/marketplace/models/device.model';
import { DeviceItem } from '../models/item';
import { ENDPOINTS } from '../constants/endpoint';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  constructor(private readonly apiService: ApiService) {}

  public getDevices(): Observable<DeviceItem[]> {
    return (
      this.apiService
        .get(ENDPOINTS.catalog.devicesList)
        .pipe(map((data) => data?.response || []))
    );
  }

  public getRecommendedDevices(
    payload: CalculatorData
  ): Observable<RecommendedDevice[]> {
    return this.apiService.post(ENDPOINTS.catalog.recommendations, payload);
  }
}
