<div class="mat-dialog__header">
  <h2 mat-dialog-title>
    {{ "titles.choose-default-card" | translate }}
  </h2>
</div>

<mat-dialog-content class="mat-typography" class="rs-default-card">
  <p class="rs-default-card__title">
    {{ "card.Please_choose_a_new_default_card" | translate }}
  </p>

  <div
    *ngFor="let card of cards$ | async"
    class="rs-default-card__item"
    [class.rs-default-card__item--selected]="card.id === selectedCard?.id"
    (click)="selectCard(card)"
  >
    <rs-card-info [card]="card"></rs-card-info>
  </div>

  <div class="rs-default-card__actions">
    <button
      mat-flat-button
      color="primary"
      class="rs-dialog-button"
      [disabled]="!selectedCard"
      (click)="save()"
    >
      {{ "buttons.save" | translate }}
    </button>
  </div>
</mat-dialog-content>
