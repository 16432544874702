import {
  MAX_CARD_LENGTH_VALUE,
  MIN_CARD_LENGTH_VALUE,
  PAYMENT_IMAGE,
  PAYMENT_SYSTEM_MEASURES,
  PAYMENT_SYSTEM_TITLES
} from '../constants/payment';
import { PaymentCard } from '../enums/payment-card.enum';
import { PaymentCardInfo, Range } from '../models/card.model';
import { createCopy } from './create-object-copy';

const definePaymentCardType = (cardNumber: string): PaymentCard => {
  if (cardNumber) {
    const paymentCardMeasure = PAYMENT_SYSTEM_MEASURES.find(measure => 
      isInRanges(measure.exceptRanges, cardNumber) ? false :
      isInRanges(measure.ranges,       cardNumber) ? true  : false
    );
  
    return paymentCardMeasure ? paymentCardMeasure.type : PaymentCard.UNKNOWN;
  }
    
  return PaymentCard.UNKNOWN;
}

const isInRanges = (ranges: Range[], cardNumber: string): boolean => {
  const rangeIndex = ranges.findIndex(range => {
    const rangeLength = range.min.toString().length;
    const croppedCardNumber = Number(cardNumber.substring(0, rangeLength));

    return (range.min === croppedCardNumber) || 
           (range.max === croppedCardNumber) || 
           ((range.min < croppedCardNumber) && (croppedCardNumber < range.max));
  });

  return rangeIndex > -1;
};

const getCardInfo = (cardNumber: string, cardType: PaymentCard): PaymentCardInfo => {
  const paymentCardMeasure = PAYMENT_SYSTEM_MEASURES.find((measure) => measure.type === cardType);
  const minCardNumberLength = (cardType === PaymentCard.UNKNOWN) ?
        MIN_CARD_LENGTH_VALUE : Math.min(...paymentCardMeasure.cardNumberLength);
  const maxCardNumberLength = (cardType === PaymentCard.UNKNOWN) ?
        MAX_CARD_LENGTH_VALUE : Math.max(...paymentCardMeasure.cardNumberLength);
  const cardNumberLength =
        (cardType === PaymentCard.UNKNOWN)         ? MAX_CARD_LENGTH_VALUE :
        (cardNumber.length >= maxCardNumberLength) ? maxCardNumberLength :
        paymentCardMeasure.cardNumberLength.find(lengthOption => (lengthOption > cardNumber.length));
  const mask = getCardNumberMask(cardNumber.length, maxCardNumberLength);
  const type = paymentCardMeasure.type;
  const title = PAYMENT_SYSTEM_TITLES[type];
  const validationCodeLength = paymentCardMeasure.validationCodeLength;
  const isValid = luhnAlgorithm(cardNumber);

  return {
    cardNumberLength,
    minCardNumberLength,
    maxCardNumberLength,
    mask,
    type,
    title,
    validationCodeLength,
    isValid
  };
};

const getCardNumberMask = (length: number, maxLength: number): (string | RegExp)[] => {
  let mask: (string | RegExp)[] = [];
  const maskBaseParts = Math.floor(length / 4);
  const maskTailPart = length % 4;

  for (let i = 1; i <= maskBaseParts; i++) {
    mask.push(/\d/, /\d/, /\d/, /\d/);

    if ((i !== maskBaseParts) || (maskTailPart !== 0)) {
      mask.push('-');
    }
  }

  for (let j = 1; j <= maskTailPart; j++) {
    mask.push(/\d/);
  }

  if (length < maxLength) {
    mask.push(/\d/);
  }

  return mask;
};

const luhnAlgorithm = (cardNumber: string): boolean => {
  const checkDigit = parseInt(cardNumber.charAt(cardNumber.length - 1) , 10);
  let isEven = true;
  let sum = 0;
  
  for (let i = cardNumber.length - 2; i >= 0; i--) {
    let digit = parseInt(cardNumber.charAt(i), 10);
    
    if (isEven && (digit *= 2) > 9) {
      digit -= 9;
    }
    
    sum += digit;
    isEven = !isEven;
  }

  return ((sum * 9) % 10 === checkDigit);
}

const replaceCardNumber = (cardNumber: string): string => createCopy(cardNumber.toString().replace(/-/gi,''));

const getPaymentCardTitle = (paymentSystem: PaymentCard): string =>
  PAYMENT_SYSTEM_TITLES[paymentSystem === null ? PaymentCard.VISA : paymentSystem];

const getPaymentCardImageSrc = (paymentSystem: PaymentCard): string =>
  PAYMENT_IMAGE.rootSVG +
  PAYMENT_IMAGE[(paymentSystem === null) ? PaymentCard.VISA : paymentSystem] +
  PAYMENT_IMAGE.typeSVG;


  export {
    definePaymentCardType,
    getCardInfo,
    getCardNumberMask,
    replaceCardNumber,
    getPaymentCardTitle,
    getPaymentCardImageSrc
  };