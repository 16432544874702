import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from 'src/app/shared/constants/endpoint';
import { Order, OrderResult } from 'src/app/shared/models/order.model';
import { ApiService, PayService } from 'src/app/shared/services/api.service';
import { Payment } from '../models/payment.model';

@Injectable({ providedIn: 'root' })
export class OrderService {
  constructor(private apiService: ApiService, private payService: PayService) {
  }

  private getQueryParameters(parameters: object = {}): string {
    const keys                    = Object.keys(parameters);
    const queryParameters: string =
              keys.reduce((accumulator: string, key: string) =>
                  accumulator + (parameters[key] ? ('&' + key + '=' + parameters[key]) : ''), '?pageSize=-1');
    return queryParameters ? queryParameters : '';
  }

  public getOrders({ next, previous, pageSize, status, service }: {
    next?: string;
    previous?: string;
    pageSize?: number;
    status?: string;
    service?: string
  } = { next: '', previous: '', status: '', service: '' }): Observable<OrderResult> {
    const url = ENDPOINTS.order.getAllOrders + this.getQueryParameters({ next, previous, status, service });

    return this.apiService.get(url);
  }

  public getOrderById(id: string): Observable<Order> {
    return this.apiService.get(`${ENDPOINTS.order.getAllOrders}/${id}`);
  }

  public getPaymentInformationByOrderId(id: string): Observable<Payment[]> {
    return this.payService.get(ENDPOINTS.wallet.orderPaymentInformation.split('{{orderId}}').join(id));
  }
}
