import { Component } from '@angular/core';
import { fadeIn } from 'src/app/shared/animations/fade-in.animation';

@Component({
  selector: 'rs-notification-tooltip',
  templateUrl: './notification-tooltip.component.html',
  styleUrls: ['./notification-tooltip.component.scss'],
  animations: [fadeIn]
})

export class NotificationTooltipComponent {}
