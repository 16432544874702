import { Component, Input } from '@angular/core';

@Component({
             selector   : 'rs-image-or-default-icon',
             templateUrl: './image-or-default-icon.component.html',
             styleUrls  : ['./image-or-default-icon.component.scss']
           })
export class ImageOrDefaultIconComponent {
  @Input() photo: string;
  @Input() iconName: string;
  @Input() iconSRC: string;
  @Input() color: string = 'white';
}
