import { environment } from '../../../environments/environment';

export const API_ALIASES = {
  apiUrlAlias       : '$ms/',
  paymentApiUrlAlias: '$pay/'
};

export const API_ALIAS_REPLACEMENTS = {
  [API_ALIASES.apiUrlAlias]       : environment.apiUrl,
  [API_ALIASES.paymentApiUrlAlias]: environment.paymentApiUrl
};

export const AVAILABLE_MS_VERSIONS = ['/v1', '/v2'];

export const MS_NAMES = {
  auth       : 'authentication',
  userProfile: 'user-profile',
  search     : 'search',
  geo        : 'geo-location',
  payment    : 'payment',
  catalog    : 'catalog',
  order      : 'order'
};

export const MS_DEPENDS_ON_VERSIONS = {
  [AVAILABLE_MS_VERSIONS[0]]: [
    MS_NAMES.auth,
    MS_NAMES.userProfile,
    MS_NAMES.geo,
    MS_NAMES.catalog,
    MS_NAMES.order
  ],
  [AVAILABLE_MS_VERSIONS[1]]: [
    MS_NAMES.search,
  ]
};

export const ENDPOINTS = {
  loginArea  : {
    login                         : MS_NAMES.auth + '/user/login',
    getAvailabilityForLoginOptions: MS_NAMES.auth + '/user/login/availability',
    refresh                       : MS_NAMES.auth + '/user/login/refresh',
    startPointResetPasswordProcess: MS_NAMES.auth + '/recovery/password',
    confirmPassword               : MS_NAMES.auth + '/recovery/password/confirm',
    contextSwitch                 : MS_NAMES.auth + '/context-switch/{{tenantId}}'
  },
  memberArea : {
    profile: {
      getUserProfile      : MS_NAMES.userProfile + '/profile/me',
      updateProfileContact: MS_NAMES.userProfile + '/profile/me/contact',
      profilePhoto        : MS_NAMES.userProfile + '/profile/{{id}}/picture',
      changePassword      : MS_NAMES.userProfile + '/profile/me/password',
      userAddresses       : MS_NAMES.userProfile + '/profile/me/address',
      venues              : MS_NAMES.userProfile + '/profile/me/venue'
    },
  },
  geoLocation: {
    autoCompleteAddress: MS_NAMES.geo + '/location/autocomplete-address',
    address            : MS_NAMES.geo + '/location/address'
  },
  search     : {
    composed: MS_NAMES.search + '/composed'
  },
  catalog    : {
    catalog           : MS_NAMES.catalog + '/',
    recommendations   : MS_NAMES.catalog + '/marketplace/recommendations',
    devicesList       : MS_NAMES.catalog + '/item?isRockspoonVenue=true&pageSize=-1',
    detailedDeviceInfo: MS_NAMES.catalog + '/consumer/item/{{itemId}}'
  },
  wallet     : {
    cardToken              : MS_NAMES.payment + '/card-token',
    orderPaymentInformation: MS_NAMES.payment + '/consumer?orderId={{orderId}}'
  },
  order      : {
    getAllOrders      : MS_NAMES.order + '/device-marketplace',
    createCart        : MS_NAMES.order + '/ca/cart',
    changePickupMethod: MS_NAMES.order + '/ca/cart',
    addCartItem       : MS_NAMES.order + '/ca/cart/{{cartId}}/item',
    repeatCartItem    : MS_NAMES.order + '/ca/cart/{{cartId}}/repeat-item',
    deleteCartItem    : MS_NAMES.order + '/ca/cart/{{cartId}}/item',
    commitCart        : MS_NAMES.order + '/ca/cart/{{cartId}}/commit',
    placeAnOrder      : MS_NAMES.order + '/ca/{{orderId}}/place',
  },
  payment    : {
    cardNotPresent: MS_NAMES.payment + '/consumer/cardnotpresent'
  }
};

export const EXCLUDED_ROUTES_FOR_ACCESS_TOKEN = [
  'assets',
  ENDPOINTS.loginArea.login,
  ENDPOINTS.loginArea.getAvailabilityForLoginOptions,
  ENDPOINTS.loginArea.refresh,
  ENDPOINTS.loginArea.startPointResetPasswordProcess,
  ENDPOINTS.loginArea.confirmPassword,
  ENDPOINTS.search.composed
];

export const EXCLUDED_ROUTES_FOR_SHOWING_ERRORS = [
  ENDPOINTS.memberArea.profile.getUserProfile,
  ENDPOINTS.loginArea.login,
  ENDPOINTS.loginArea.startPointResetPasswordProcess,
  ENDPOINTS.loginArea.confirmPassword,
  ENDPOINTS.geoLocation.autoCompleteAddress,
  ENDPOINTS.payment.cardNotPresent,
  ENDPOINTS.geoLocation.address
];

export const MOCK_URL_PART = {
  cart : '{{cartId}}',
  order: '{{orderId}}',
  item : '{{itemId}}',
  tenantId : '{{tenantId}}'
};

export const EXCLUDED_ROUTES_FOR_VENUE_TOKEN = [
  ENDPOINTS.memberArea.profile.getUserProfile,
  ENDPOINTS.loginArea.contextSwitch.replace(MOCK_URL_PART.tenantId, ''),
  ENDPOINTS.wallet.cardToken
];
