<div *ngIf="device" class="rs-cart-item d-flex">
  <div class="rs-cart-item__image">
    <rs-image-or-default-icon
      [photo]="device.photos | photo"
      [iconName]="'devices'"
      [color]="'black'"
    ></rs-image-or-default-icon>
  </div>

  <div class="rs-cart-item__info">
    <div class="d-flex align-items-center justify-content-between">
      <div class="rs-cart-item__title">
        <p [class.rs-cart-item__title--bold]="showDownpayment">{{ item.name }}</p>
      </div>

      <div *ngIf="!showDownpayment">
        <p>{{ device.sizesAndPrices | devicePrice: {quantity: item.quantity} }}</p>
      </div>
    </div>

    <div class="mt-2 d-flex align-items-center justify-content-between">
      <div class="rs-cart-item__quantity">
        <rs-item-quantity
          [quantity]="item.quantity"
          [changeable]="true"
          [disabled]="disabled"
          (increased)="increaseItemQuantity($event)"
          (decreased)="decreaseItemQuantity($event)"
      ></rs-item-quantity>
      </div>

      <button mat-icon-button (click)="deleteItemFromCart()"> <!--TODO: add alt text and hide icon-->
        <mat-icon>delete</mat-icon>
      </button>
    </div>

    <div *ngIf="showDownpayment" class="rs-cart-item__downpayment-wrapper">
      <span class="rs-cart-item__price">
        {{ device.sizesAndPrices | devicePrice: {quantity: item.quantity} }}
      </span>
      <span class="rs-cart-item__downpayment">
        {{ "common.or" | translate}}
        {{ "payment.Downpayment__FIRSTPAY__and__EVERYDAYPAY__" | translate:
          { firstPay: device.sizesAndPrices | firstPay: {quantity: item.quantity},
            everyDayPay: device.sizesAndPrices | everyDayPay: {quantity: item.quantity}
          }
        }}
      </span>
    </div>

    <mat-divider *ngIf="showDivider"></mat-divider>
  </div>
</div>
