import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import {Store} from '@ngxs/store';

import {Navigate} from '@ngxs/router-plugin';

import {PAGES} from '../constants/pages';
import {AuthenticationState} from 'src/app/ngxs/authentication.state';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../../environments/environment';
import {SaveAuthenticationData, SaveRefreshToken} from "../../ngxs/authentication.actions";
import {AuthenticationService} from "../services/authentication.service";

@Injectable({
  providedIn: 'root',
})
export class NotAuthenticationGuard implements CanActivate {
  constructor(private store: Store, private cookieService: CookieService, private authService: AuthenticationService) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let isAuth = this.store.selectSnapshot(AuthenticationState.isAuth);
    const refreshToken = this.cookieService.get(`${environment.prefix}refreshToken`);
    if (refreshToken) {
      this.store.dispatch(new SaveRefreshToken(refreshToken));
      this.authService.refreshToken(this.store.selectSnapshot(AuthenticationState.refreshToken)).subscribe((data) => {
        this.store.dispatch(new SaveAuthenticationData(data));
        isAuth = this.store.selectSnapshot(AuthenticationState.isAuth);
        if (isAuth) {
          console.log('here');
          this.redirectToHomePage();
        }
        return !isAuth;
      });
    } else {
      if (isAuth) {
        this.redirectToHomePage();
      }

      return !isAuth;
    }
  }

  private redirectToHomePage(): void {
    console.log('here 2');
    this.store.dispatch(new Navigate([PAGES.MARKETPLACE]));
  }
}
