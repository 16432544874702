const ORDER_TYPE = 'delivery';
const ORDER_DOMAIN = 'e-commerce';

const ORDER_INITIALIZATION_PAYLOAD = {
  orderType: 'delivery',
  venueId: '', // Please don't forget to change venueId to existing in a device info
  domain: 'e-commerce',
};

export {
  ORDER_TYPE,
  ORDER_DOMAIN,
  ORDER_INITIALIZATION_PAYLOAD
};
