import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';

import {
  State,
  Store,
  Action,
  StateContext,
  createSelector,
  Selector,
} from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { SetSideVisibility } from 'src/app/ngxs/app.actions';
import {
  GetRecommendDevices,
  GetDevicesData,
  ChangeRecommendedDeviceCount,
} from './device.action';

import { PAGES } from 'src/app/shared/constants/pages';
import { Spinner } from 'src/app/shared/classes/spinner.class';
import { DevicesService } from '../shared/services/devices.service';

import { RecommendedDevice, TotalData } from '../modules/marketplace/models/device.model';
import { DeviceItem } from '../shared/models/item';

import { getDevicePriceInCents } from '../shared/utils/price';
import { PhotoPipe } from '../shared/pipes/device.pipe';

export class DevicesStateModel {
  devices: DeviceItem[];
  recomendedDevices: RecommendedDevice[];
}

@State<DevicesStateModel>({
  name: 'devices',
  defaults: {
    devices: [],
    recomendedDevices: null,
  },
})
@Injectable()
export class DeviceState extends Spinner {
  constructor(private devicesService: DevicesService, protected store: Store) {
    super(store);
  }

  static getDeviceById(deviceId: string) {
    return createSelector([DeviceState], ({devices}: DevicesStateModel): DeviceItem => {
      return devices.find(({id}) => id === deviceId);
    });
  }

  @Selector()
  static devicesPrice({devices}: DevicesStateModel): Record<string, number> {
    return (devices ?? []).reduce((accumulator, item) => {
      accumulator[item.id] = getDevicePriceInCents(item.sizesAndPrices);

      return accumulator;
    }, {} as Record<string, number>);
  }

  @Selector()
  static recommendedDevices({recomendedDevices}: DevicesStateModel): RecommendedDevice[] {
    return recomendedDevices ?? [];
  }

  @Selector()
  static recommendedDevicesTotalData({recomendedDevices}: DevicesStateModel): TotalData {
    return recomendedDevices.reduce((accumulator, {count, sizesAndPrices}) => {
      accumulator.count = accumulator.count + count;
      accumulator.price = accumulator.price + getDevicePriceInCents(sizesAndPrices) * count;

      return accumulator;
    }, {count: 0, price: 0});
  }

  static getDevicePhotoById(deviceId: string) {
    // TODO: check available photos in recommended devices array,
    // maybe we can remove this selector after BE integration
    return createSelector([DeviceState], ({devices}: DevicesStateModel): string => {
      const device = devices.find(({id}) => id === deviceId);
      const photoPipe = new PhotoPipe();
      return photoPipe.transform(device.photos);
    });
  }

  @Action(GetDevicesData)
  getDevicesData({ patchState }: StateContext<DevicesStateModel>) {
    this.showSpinner();

    return this.devicesService.getDevices().pipe(
      tap({
        next: (devices: DeviceItem[] = []) => {
          this.hideSpinner();
          patchState({devices});
        },
      })
    );
  }

  @Action(GetRecommendDevices)
  getRecommendedDevices(
    { patchState }: StateContext<DevicesStateModel>,
    { payload }: GetRecommendDevices
  ) {
    this.showSpinner();
    return this.devicesService.getRecommendedDevices(payload).pipe(
      tap({
        next: (recomendedDevices) => {
          patchState({
            recomendedDevices: recomendedDevices ?? [],
          });
          this.hideSpinner();
          this.store.dispatch([
            new Navigate([PAGES.CALCULATOR_RESULT]),
            new SetSideVisibility(false),
          ]);
        },
      })
    );
  }

  @Action(ChangeRecommendedDeviceCount)
  changeRecommendedDeviceCount(
    { patchState, getState }: StateContext<DevicesStateModel>,
    { itemId, count }: ChangeRecommendedDeviceCount
  ) {
    const recomendedDevices = getState().recomendedDevices.map((item) => {
      if (item.id !== itemId) {
        return item;
      }

      return {...item, count};
    });
    patchState({
      recomendedDevices
    });
  }
}
