import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'rs-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss'],
})
export class NoAccessComponent {
  @HostBinding('class') class = 'col d-flex flex-column align-items-center justify-content-center';

  constructor(private readonly router: Router) {
  }

  public backClicked(): void {
    if (window.history.length > 2) {
      window.history.go(-2);
    } else {
      this.router.navigate(['/home']);
    }
  }
}
