export class GetOrders {
  static readonly type = '[My orders] Get Orders';
  constructor(public parameters?: {
    pageSize?: number;
    status?: string;
    service?: string
  }) {}
}

export class GetNextOrders {
  static readonly type = '[My orders] Get Next Orders';
  constructor(public parameters?: {
    pageSize?: number;
    status?: string;
    service?: string
  }) {}
}

export class GetOrdersById {
  static readonly type = '[My orders] Get Orders by Id';
  constructor(public id: string) {}
}

export class GetOrdersPaymentInformation {
  static readonly type = '[My orders] Get Payment Information';
  constructor(public id: string) {}
}

export class ClearOrderData {
  static readonly type = '[My orders] Clear Order Data';
}
