import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { ENDPOINTS } from '../constants/endpoint';
import {
  generateUrlForMultipleOrderItemIds,
  getEndpointForCartId,
  getEndpointForOrderId,
  getOrderItemQueryParameter
} from '../utils/endpoint';
import { ItemDetails, CartValue, CommitedCart } from '../../modules/cart/cart.models';
import { ORDER_INITIALIZATION_PAYLOAD } from '../../modules/cart/cart.constants';
import { VenueAddress } from '../models/address.model';

@Injectable({providedIn: 'root'})
export class CartService {
  constructor(private apiService: ApiService) {}

  public initializeCart(venueId: string): Observable<{ id: string }> {
    ORDER_INITIALIZATION_PAYLOAD.venueId = venueId;

    return this.apiService.post(ENDPOINTS.order.createCart, ORDER_INITIALIZATION_PAYLOAD);
  }

  public addItem(cartId: string,
                 items: ItemDetails[]): Observable<CartValue> {
    const url = getEndpointForCartId(ENDPOINTS.order.addCartItem, cartId);

    return this.apiService.post(url, {
      items
    });
  }

  public repeatItem(cartId: string,
         orderItemId: string): Observable<CartValue> {
    const url = getEndpointForCartId(ENDPOINTS.order.repeatCartItem, cartId);
    const orderItemIDs = [orderItemId];

    return this.apiService.post(url, {orderItemIDs});
  }

  public deleteItems(cartId: string, orderItemIds: string[]) {
    const generatedRestUrlPart = generateUrlForMultipleOrderItemIds(orderItemIds);
    const url = getEndpointForCartId(ENDPOINTS.order.deleteCartItem, cartId) + generatedRestUrlPart;
    return this.apiService.delete(url);
  }

  public deleteItem(cartId: string,
    orderItemId: string): Observable<CartValue> {
    const generatedRestUrlPart = getOrderItemQueryParameter(true, orderItemId);
    const url = getEndpointForCartId(ENDPOINTS.order.deleteCartItem, cartId) + generatedRestUrlPart;

    return this.apiService.delete(url);
  }

  public commitCart(cartId: string, address: VenueAddress): Observable<CommitedCart> {
    const url = getEndpointForCartId(ENDPOINTS.order.commitCart, cartId);
    return this.apiService.post(url, {
      address,
      isASAP: true
    });
  }

  public order(orderId: string, downpaymentAmount: number, token: string): Observable<any> {
    const url = getEndpointForOrderId(ENDPOINTS.order.placeAnOrder, orderId);
    const payload = {
      downpaymentAmount,
      cctoken: token,
      billingPeriod: 12
    };

    return this.apiService.post(url, payload);
  }
}
