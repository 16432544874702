import { Injectable, NgZone } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Navigate } from '@ngxs/router-plugin';

import { TIMEOUT_FOR_NOTIFICATION_ERASE } from '../shared/constants/common';
import { PAGES } from '../shared/constants/pages';
import {
  AddAllRecommendedDevicesToCart,
  AddItemToCart,
  BuyNowRecommendedDevices,
  ClearCart,
  ClearCartNotification,
  DecreaseCartItem,
  DeleteDeviceItemFromCart,
  IncreaseCartItem,
  SetDisableCartItems,
  ShowAddedItemsToCart,
  UpdateCartValue,
  UpdatesItemsInCart
} from './cart.actions';
import { CartService } from '../shared/services/cart.service';
import { CartItem, CartItemWithQuantity, CartValue, CartValueCheck, ItemDetails } from '../modules/cart/cart.models';
import { CartNotification, CartNotificationItem } from '../shared/models/cart-notification.model';
import { RecommendedDevice } from '../modules/marketplace/models/device.model';
import { DeviceState } from './device.state';
import { DeviceItem } from '../shared/models/item';
import { of } from 'rxjs';
import {
  generateQueriesBasedOnQuantity,
  sequential,
  getDeviceMetadata,
  getLastItemInCart,
  getItemsForDelete,
  filterCartItemsById,
  getNotificationItem
} from '../shared/utils/cart-state';
import { catchError, tap } from 'rxjs/operators';
import { AppState } from './app.state';
import { Spinner } from '../shared/classes/spinner.class';

// import { SetCommitData } from './ordering.actions';

const initNotification = {items: null, isShow: false};

interface CartStateModel {
  cartId: string;
  value: CartValue;
  disable: boolean;
  notification: CartNotification;
}

@State<CartStateModel>({
                         name: 'cart',
                         defaults: {
                           cartId: null,
                           value: null,
                           disable: false,
                           notification: {...initNotification},
                         }
                       })
@UntilDestroy()
@Injectable()
export class CartState extends Spinner  {
  private cartTimeoutId: ReturnType<typeof setTimeout>;

  constructor(
    public readonly store: Store,
    private readonly ngZone: NgZone,
    private readonly cartService: CartService,
  ) {
    super(store);
  }

  @Selector()
  static cartId(state: CartStateModel) {
    return state.cartId;
  }

  @Selector()
  static disableCartItems(state: CartStateModel) {
    return state.disable;
  }

  static disabledDeviceButtons() {
    return createSelector(
      [AppState.cartIsOpen, CartState.disableCartItems],
      (isOpen, disabled) => !isOpen && disabled
    );
  }

  static disabledCalculatorResultButtons() {
    return createSelector(
      [CartState.disabledDeviceButtons(), DeviceState.recommendedDevices],
      (disabled, recommendedDevices) => disabled || !recommendedDevices.length
    );
  }

  @Selector()
  static check(state: CartStateModel): CartValueCheck {
    return state.value?.check ?? {} as CartValueCheck;
  }

  @Selector()
  static items(state: CartStateModel): CartItem[] {
    return state.value?.items ?? [];
  }

  @Selector()
  static hideBadge(state: CartStateModel): boolean {
    return !(state && state.value?.items && state.value?.items?.length);
  }

  @Selector()
  static badgeNumber(state: CartStateModel): string {
    const badgeNumber = (state.value?.items?.length) ? state.value?.items?.length : 0;

    return badgeNumber.toString();
  }

  @Selector()
  static itemsWithQuantity(cartState: CartStateModel): CartItemWithQuantity[] {
    const values = {};

    (cartState.value.items ?? []).forEach((item) => {
      const itemId = item.itemId;

      if (!values[item.itemId]) {
        values[itemId] = {...item, quantity: 1};
      } else {
        values[itemId].quantity = values[itemId].quantity + 1;
      }
    });

    return Object.values(values);
  }

  static getTotalPrice() {
    return createSelector(
      [CartState.itemsWithQuantity, DeviceState.devicesPrice],
      (
        itemsWithQuantity: CartItemWithQuantity[],
        devicesPrice: Record<string, number>
      ): number => {
        return itemsWithQuantity.reduce(
          (accumulator, {quantity, itemId}) => {
            const price = devicesPrice[itemId] ?? 0;
            return accumulator += price * quantity;
          },
          0
        );
      }
    );
  }

  @Selector()
  static getCartCheck({value}: CartStateModel): CartValueCheck {
    return value?.check ?? {} as CartValueCheck;
  }

  static getItemsQuantityInCart(deviceId: string) {
    return createSelector([CartState.itemsWithQuantity], (items: CartItemWithQuantity[]) => {
      const cartItem = items.find(item => item.itemId === deviceId);
      return cartItem.quantity;
    });
  }

  static getOrderItemId(deviceId: string) {
    return createSelector([CartState.itemsWithQuantity], (items: CartItemWithQuantity[]) => {
      const cartItem = items.find(item => item.itemId === deviceId);
      return cartItem.orderItemId;
    });
  }


  @Selector()
  static notification(state: CartStateModel): CartNotification {
    return state.notification;
  }

  @Action(UpdateCartValue)
  updateCartValue({ patchState }: StateContext<CartStateModel>, { cartValue: value }: UpdateCartValue) {
    patchState({ value });
  }


  @Action(AddItemToCart)
  addItemToCart({ patchState, getState }: StateContext<CartStateModel>, { item }: AddItemToCart) {
    const hasValue = !!getState().value?.items?.length;
    const venueId = this.getVenueId(item.itemId);

    if (hasValue) {
      this.store.dispatch(new UpdatesItemsInCart(item));
    } else {
      return this.cartService.initializeCart(venueId).subscribe(({id: cartId}) => {
        patchState({cartId});

        return this.store.dispatch(
          [
            // new SetCommitData(null)
           new UpdatesItemsInCart(item)
            ]);
      });
    }
  }

  @Action(UpdatesItemsInCart)
  updateItemsInCart({ patchState, getState }: StateContext<CartStateModel>, { item }: UpdatesItemsInCart) {
    const cartId = getState().cartId;
    const device: DeviceItem = this.store.selectSnapshot(DeviceState.getDeviceById(item.itemId));
    const metadata = getDeviceMetadata(device.sizesAndPrices);
    this.store.dispatch(new SetDisableCartItems(true));

    return this.cartService.addItem(cartId, [{...item, metadata}]).subscribe((value: CartValue) => {
      const lastItem = getLastItemInCart(value.items);
      // TODO: use available data from CartValue 'entries' after integration with BE
      const notificationItem: CartNotificationItem = {
        photo: this.store.selectSnapshot(DeviceState.getDevicePhotoById(item.itemId)),
        quantity: item.quantity,
        name: lastItem.name
      };
      patchState({ value, disable: false });
      return this.store.dispatch([new ShowAddedItemsToCart([notificationItem])]);
    },
    () => this.store.dispatch(new SetDisableCartItems(false)));
  }

  @Action(IncreaseCartItem)
  increaseCartItem(
    { getState, patchState }: StateContext<CartStateModel>,
    { quantity, orderItemId, showNotification }: IncreaseCartItem
  ) {
    const cartId = getState().cartId;
    const queries = generateQueriesBasedOnQuantity(quantity, this.cartService.repeatItem(cartId, orderItemId));
    this.store.dispatch(new SetDisableCartItems(true));

    return of(null)
      .pipe(
        sequential(...queries),
        untilDestroyed(this),
      ).subscribe((value: CartValue) => {
        if (value) {
          patchState({ value});

          if (showNotification) {
            const itemId = getLastItemInCart(value.items)?.itemId;
            this.showUpdatedItemInCart(itemId, value.items);
          }
        }
        this.store.dispatch(new SetDisableCartItems(false));
      },
      (error) => this.store.dispatch(new SetDisableCartItems(false)));
  }

  @Action(DecreaseCartItem)
  decreaseCartItem(
    { patchState, getState }: StateContext<CartStateModel>,
    { quantity, deviceId, showNotification }: DecreaseCartItem
  ) {
    const {cartId, value} = getState();
    const itemsForDelete = getItemsForDelete(value.items, deviceId, quantity);
    const queries = itemsForDelete.map(({orderItemId}) => this.cartService.deleteItem(cartId, orderItemId));
    this.store.dispatch(new SetDisableCartItems(true));

    return of(null)
      .pipe(
        sequential(...queries),
        untilDestroyed(this)
      ).subscribe((cartValue: CartValue) => {
        if (cartValue) {
          patchState({value: cartValue});

          if (showNotification) {
            this.showUpdatedItemInCart(deviceId, cartValue.items);
          }

          this.store.dispatch(new SetDisableCartItems(false));
        }
      },
      (error) => this.store.dispatch(new SetDisableCartItems(false)));
  }

  @Action(DeleteDeviceItemFromCart)
  deleteDeviceItemFromCart(
    { patchState, getState }: StateContext<CartStateModel>,
    { deviceId }: DeleteDeviceItemFromCart
  ) {
    const {cartId, value} = getState();
    const filteredItems = filterCartItemsById(value.items, deviceId);
    const orderItemIds = filteredItems.map(({orderItemId}) => orderItemId);
    this.store.dispatch(new SetDisableCartItems(true));

    return (orderItemIds.length === 1
      ? this.cartService.deleteItem(cartId, orderItemIds[0])
      : this.cartService.deleteItems(cartId, orderItemIds)
      ).pipe(
        tap((cartValue: CartValue) => {
          if (cartValue) {
            patchState({value: cartValue});
          }
          this.store.dispatch(new SetDisableCartItems(false));
        }),
        catchError(() => this.store.dispatch(new SetDisableCartItems(false)))
      );
  }

  @Action(SetDisableCartItems)
  setDisableCartItems(
    { patchState }: StateContext<CartStateModel>,
    { disableState: disable }: SetDisableCartItems
  ) {
    patchState({ disable });
  }

  @Action(ClearCart)
  clearCart({ patchState }: StateContext<CartStateModel>) {
    patchState({
      value: null,
      cartId: null
    });
  }

  @Action(ShowAddedItemsToCart)
  showAddedItemToCart({ patchState }: StateContext<CartStateModel>, { items }: ShowAddedItemsToCart) {
    patchState({ notification: { items, isShow: true } });

    if (this.cartTimeoutId) {
      clearTimeout(this.cartTimeoutId);
    }

    this.cartTimeoutId = setTimeout(() => {
      this.ngZone.run(() => {
        this.store.dispatch([new ClearCartNotification()]);
      });
    }, TIMEOUT_FOR_NOTIFICATION_ERASE);
  }

  @Action(ClearCartNotification)
  clearCartNotification({ patchState }: StateContext<CartStateModel>) {
    patchState({ notification: {...initNotification}});
  }

  @Action(BuyNowRecommendedDevices)
  buyNowRecommendedDevices(
    { patchState, getState }: StateContext<CartStateModel>
  ) {
    this.showSpinner();
    const recommendedDevices = this.getRecommendedDevices();
    const hasValue = !!getState().value?.items?.length;

    if (hasValue) {
      const cartId = getState().cartId;
      this.addDevicesAndBuy(recommendedDevices, cartId, patchState);
    } else {
      const venueId = this.getVenueId(recommendedDevices[0].id);
      this.cartService.initializeCart(venueId).subscribe(({id: cartId}) => {
        patchState({cartId});
        this.addDevicesAndBuy(recommendedDevices, cartId, patchState);
      });
    }
  }

  @Action(AddAllRecommendedDevicesToCart)
  addAllRecommendedDevicesToCart(
    { patchState, getState }: StateContext<CartStateModel>
  ) {
    this.store.dispatch(new SetDisableCartItems(true));
    const recommendedDevices = this.getRecommendedDevices();
    const hasValue = !!getState().value?.items?.length;

    if (hasValue) {
      const cartId = getState().cartId;
      this.addRecommendedDevices(recommendedDevices, cartId, patchState);
    } else {
      const venueId = this.getVenueId(recommendedDevices[0].id);
      this.cartService.initializeCart(venueId).subscribe(({id: cartId}) => {
        patchState({cartId});

        this.addRecommendedDevices(recommendedDevices, cartId, patchState);
      },
      () => this.store.dispatch(new SetDisableCartItems(false)));
    }
  }

  private addDevicesAndBuy(
    recommendedDevices: RecommendedDevice[],
    cartId: string,
    patchState: (value: Partial<CartStateModel>) => CartStateModel
  ): void {
    const itemDetails = this.getItemDetails(recommendedDevices);

    this.cartService.addItem(cartId, itemDetails).subscribe((value) => {
      patchState({ value });
      this.hideSpinner();
      this.store.dispatch([new Navigate([PAGES.CHECKOUT])]);
    });
  }

  private getRecommendedDevices(): RecommendedDevice[] {
    return this.store.selectSnapshot((state) => state.devices.recomendedDevices);
  }

  private getItemDetails(recommendedDevices: RecommendedDevice[]): ItemDetails[] {
    return recommendedDevices.map((item) => {
      const device = this.store.selectSnapshot(DeviceState.getDeviceById(item.id));
      return  {
        itemId  : item.id,
        quantity: item.count,
        metadata: getDeviceMetadata(device.sizesAndPrices)
      } as ItemDetails;
    });
  }

  private addRecommendedDevices(
    recommendedDevices: RecommendedDevice[],
    cartId: string,
    patchState: (value: Partial<CartStateModel>) => CartStateModel
  ): void {
    const itemDetails = this.getItemDetails(recommendedDevices);

    this.cartService.addItem(cartId, itemDetails).subscribe((value) => {
      patchState({ value, disable: false });

      const notificationItems = recommendedDevices.map((device) => ({
        photo: this.store.selectSnapshot(DeviceState.getDevicePhotoById(device.id)),
        quantity: device.count,
        name: device.title
      }));
      this.store.dispatch([new ShowAddedItemsToCart(notificationItems)]);
    },
    () => this.store.dispatch(new SetDisableCartItems(false)));
  }

  private showUpdatedItemInCart(deviceId: string, items: CartItem[] = []): void {
    const updatedItems = items.filter(({itemId}) => itemId === deviceId);

    if (!updatedItems.length) {
      return;
    }

    const notificationItem: CartNotificationItem = getNotificationItem(
      this.store.selectSnapshot(DeviceState.getDevicePhotoById(deviceId)),
      updatedItems
    );

    this.store.dispatch([new ShowAddedItemsToCart([notificationItem])]);
  }

  private getVenueId(itemId: string): string {
    return this.store.selectSnapshot(DeviceState.getDeviceById(itemId))?.venueId;
  }
}
