import { Component, Input, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';

import { CartItemWithQuantity } from '../cart.models';
import { DecreaseCartItem, DeleteDeviceItemFromCart, IncreaseCartItem } from '../../../ngxs/cart.actions';
import { DeviceState } from 'src/app/ngxs/device.state';
import { DeviceItem } from 'src/app/shared/models/item';

@Component({
  selector: 'rs-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {
  @Input() item: CartItemWithQuantity;
  @Input() showDownpayment = false;
  @Input() showDivider = false;
  @Input() disabled = false;

  public device: DeviceItem;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.device = this.store.selectSnapshot(DeviceState.getDeviceById(this.item.itemId));
  }

  public deleteItemFromCart(): void {
    this.store.dispatch(new DeleteDeviceItemFromCart(this.item.itemId));
  }

  public increaseItemQuantity(quantity: number): void {
    this.store.dispatch(new IncreaseCartItem(quantity, this.item.orderItemId, false));
  }

  public decreaseItemQuantity(quantity: number): void {
    this.store.dispatch(new DecreaseCartItem(quantity, this.item.itemId, false));
  }
}
