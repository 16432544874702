import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SetSideVisibility } from '../../../../ngxs/app.actions';
import {
  CALCULATOR_STEPS,
  TOGGLE_ITEMS,
} from 'src/app/modules/marketplace/constants/calculator.constants';
import { GetRecommendDevices } from 'src/app/ngxs/device.action';
import { CalculatorStepControl } from 'src/app/modules/marketplace/models/device.model';

@Component({
  selector: 'rs-calculator-sidenav',
  templateUrl: './calculator-sidenav.component.html',
  styleUrls: ['./calculator-sidenav.component.scss'],
})
export class CalculatorSidenavComponent implements OnInit {
  public readonly TOGGLE_ITEMS = TOGGLE_ITEMS;
  public CALCULATOR_CONTROLS: CalculatorStepControl[] = [];
  public calculatorForm: FormGroup;

  constructor(private store: Store, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  public close(): void {
    this.store.dispatch(new SetSideVisibility(false));
  }

  public clear(): void {
    this.calculatorForm.reset();
  }

  public find(): void {
    this.store.dispatch(new GetRecommendDevices(this.calculatorForm.value));
  }

  private initForm(): void {
    this.CALCULATOR_CONTROLS = CALCULATOR_STEPS.reduce(
      (acc, prev) => [...acc, ...prev.controls],
      []
    );
    const controls = this.CALCULATOR_CONTROLS.reduce((acc, current) => {
      acc[current.name] = this.formBuilder.control(null, current.validators);
      return acc;
    }, {});
    this.calculatorForm = this.formBuilder.group(controls);
  }
}
