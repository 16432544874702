import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SignIn, SignInError } from '../../ngxs/authentication.actions';
import { REGEX } from '../../shared/constants/regular-expressions';
import { PAGES } from 'src/app/shared/constants/pages';
import { MIN_PASSWORD_LENGTH } from 'src/app/shared/constants/auth';
import { AuthenticationState } from 'src/app/ngxs/authentication.state';

@UntilDestroy()
@Component({
  selector: 'rs-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  public readonly PAGES = PAGES;
  public signInForm: FormGroup;
  public showPassword = false;
  public passwordControl: FormControl;
  public usernameControl: FormControl;
  public signInError: string;

  @Select(AuthenticationState.signInError) signInError$: Observable<string>;

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit() {
    this.initForm();
    this.store.dispatch(new SignInError());

    this.signInError$
      .pipe(untilDestroyed(this))
      .subscribe((signInError) =>
        this.setEmailOrPasswordIsIncorrectError(signInError)
      );
  }

  public signIn(): void {
    if (this.signInForm.valid) {
      this.store.dispatch(new SignIn(this.signInForm.getRawValue()));
    }
  }

  private initForm(): void {
    this.signInForm = this.formBuilder.group({
      username: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(REGEX.EMAIL),
        ],
      ],
      password: [
        '',
        [Validators.required,
        Validators.minLength(MIN_PASSWORD_LENGTH)
      ]
    ]});
    this.passwordControl = this.signInForm.get('password') as FormControl;
    this.usernameControl = this.signInForm.get('username') as FormControl;
  }

  public isInvalidControl(control: FormControl): boolean {
    return control.invalid && (control.dirty || control.touched);
  }

  public setEmailOrPasswordIsIncorrectError(signInError: string): void {
    this.signInError = signInError ?? null;
    this.usernameControl.setErrors({ signInError: this.signInError});

    if (!signInError) {
      this.usernameControl.updateValueAndValidity();
    }
  }

  public clearEmailOrPasswordIsIncorrectError(): void {
    if (this.signInError) {
      this.store.dispatch(new SignInError());
    }
  }
}
