import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { debounceTime, filter, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { INPUT_DEBOUNCE_TIME, ZIP_CODE_MASK } from '../../constants/common';
import { Address, AddressFormValue } from '../../models/address.model';

@UntilDestroy()
@Component({
  selector: 'rs-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnChanges, OnInit {
  @Input() address: Address;
  @Input() invalid: boolean;

  @Output() formChanged = new EventEmitter<AddressFormValue>();

  public readonly ZIP_CODE_MASK = ZIP_CODE_MASK;

  public addressForm = this.formBuilder.group({
    address1: this.formBuilder.control(null, [
      Validators.required
    ]),
    address2: this.formBuilder.control(null),
    city: this.formBuilder.control(null, [
      Validators.required
    ]),
    zipcode: this.formBuilder.control(null, [
      Validators.required
    ]),
    state: this.formBuilder.control(null, [
      Validators.required
    ]),
    isDefault: this.formBuilder.control(true),
  });

  constructor(private formBuilder: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.address?.currentValue) {
      this.addressForm.setValue({
        address1: this.address.address1,
        address2: this.address.address2,
        city: this.address.city,
        zipcode: this.address.zipcode,
        state: this.address.state,
        isDefault: true
      });
    }
  }

  ngOnInit(): void {
    this.addressForm.valueChanges
      .pipe(
        debounceTime(INPUT_DEBOUNCE_TIME),
        tap(() => {
          if (this.addressForm.invalid) {
            this.formChanged.emit(null);
          }
        }),
        filter(() => this.addressForm.valid),
        untilDestroyed(this)
      )
      .subscribe((value) => {
        this.formChanged.emit({
          ...value,
          country: '',
        });
      });

    if (this.addressForm.value && this.addressForm.valid) {
      this.addressForm.patchValue(this.addressForm.value)
    }
  }
}
