import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {SimpleNotificationsModule} from 'angular2-notifications';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {PluginsModule} from './shared/modules/plugins.module';
import {MainModulesModule} from './shared/modules/main-modules.module';
import {SpinnerModule} from './shared/components/spinner/spinner.module';
import {KeyInterceptor} from './shared/interceptors/key.interceptor';
import {EndpointInterceptor} from './shared/interceptors/endpoint.interceptor';
import {AccessTokenInterceptor} from './shared/interceptors/access-token.interceptor';
import {ErrorHandlerInterceptor} from './shared/interceptors/error-handler.interceptor';
import {CookieService} from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    PluginsModule,
    MainModulesModule,
    SpinnerModule,
    SimpleNotificationsModule,
  ],
  providers: [
    CookieService,
    {provide: HTTP_INTERCEPTORS, useClass: EndpointInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
