<div class="mat-dialog__header">
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
  <h2 mat-dialog-title>
    {{ 'order.' + dialogTitle | translate }}
  </h2>
</div>

<mat-dialog-content class="mat-typography rs-order-confirmation">
  <p class="rs-order-confirmation__message">
    {{ "order.thank-for-order" | translate }}
  </p>
  <p class="rs-order-confirmation__message">
    {{ "order.check-email-message" | translate }}
  </p>
</mat-dialog-content>
