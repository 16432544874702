import { Injectable, NgZone } from '@angular/core';

import { tap } from 'rxjs/operators';

import { State, Store, Action, StateContext, Selector } from '@ngxs/store';

import {
  GetWalletInitData,
  AddCard,
  DeleteCard,
  EditCard,
  ClearWallet,
} from './wallet.actions';
import { Card } from 'src/app/shared/models/card.model';
import { Spinner } from '../shared/classes/spinner.class';
import { WalletService } from '../shared/services/wallet.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectDefaultCardComponent } from '../modules/wallet/components/select-default-card/select-default-card.component';
import { DIALOG_PARAMETERS } from '../shared/constants/common';

interface WalletStateModel {
  cards: Card[];
}

@State<WalletStateModel>({
  name: 'wallet',
  defaults: {
    cards: [],
  },
})
@Injectable()
export class WalletState extends Spinner {
  constructor(
    protected readonly store: Store,
    private readonly walletService: WalletService,
    private readonly dialog: MatDialog,
    private readonly ngZone: NgZone
  ) {
    super(store);
  }

  @Selector()
  static defaultCard({ cards }: WalletStateModel) {
    return cards.find((card) => card.default);
  }

  @Selector()
  static cards({ cards }: WalletStateModel): Card[] {
    return cards;
  }

  @Action(GetWalletInitData)
  getWalletInitData({ patchState }: StateContext<WalletStateModel>) {
    this.showSpinner();

    return this.walletService.getCards().pipe(
      tap((cards: Card[]) => {
        this.hideSpinner();
        this.checkDefaultCards(cards);
        cards.sort((card) => (card.default ? -1 : 1));
        patchState({ cards });
      })
    );
  }

  @Action(AddCard)
  addCard(_: StateContext<WalletStateModel>, { newCardToAdd }: AddCard) {
    this.showSpinner();

    return this.walletService.createCard(newCardToAdd).pipe(
      tap(() => {
        this.hideSpinner();
        this.store.dispatch(new GetWalletInitData());
      })
    );
  }

  @Action(EditCard)
  editCard(_: StateContext<WalletStateModel>, { card, cardToEdit }: EditCard) {
    this.showSpinner();

    return this.walletService.editCard(cardToEdit, card.id).pipe(
      tap(() => {
        this.hideSpinner();
        this.store.dispatch(new GetWalletInitData());
      })
    );
  }

  @Action(DeleteCard)
  deleteCard(
    { patchState, getState }: StateContext<WalletStateModel>,
    { cardId }: DeleteCard
  ) {
    this.showSpinner();

    return this.walletService.deleteCard(cardId).pipe(
      tap(() => {
        this.hideSpinner();
        patchState({
          cards: getState().cards.filter(({ id }) => id !== cardId),
        });
      })
    );
  }

  @Action(ClearWallet)
  clearWallet({ patchState }: StateContext<WalletStateModel>) {
    patchState({ cards: [] });
  }

  private checkDefaultCards(cards: Card[]): void {
    if (cards.length && !cards.find((card) => card.default)) {
      this.ngZone.run(() => {
        this.dialog.open(
          SelectDefaultCardComponent,
          DIALOG_PARAMETERS.chooseDefaultCard
        );
      });
    }
  }
}
