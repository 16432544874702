import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { LayoutRoutingModule } from './layout-routing.module';
import { HeaderComponent } from './components/header/header.component';
import { CalculatorSidenavComponent } from './components/calculator-sidenav/calculator-sidenav.component';
import { MenuModule } from 'src/app/shared/components/menu/menu.module';
import { ImageOrDefaultIconModule } from '../../shared/modules/ui/image-or-default-icon/image-or-default-icon.module';
import { UserVenuesComponent } from './components/user-venues/user-venues.component';
import { VenueLogoComponent } from './components/venue-logo/venue-logo.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { CartModule } from '../cart/cart.module';
import { TabsModule } from 'src/app/shared/modules/ui/tabs/tabs.module';
import { NotificationTooltipModule } from 'src/app/shared/modules/ui/notification-tooltip/notification-tooltip.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { SpinnerModule } from 'src/app/shared/components/spinner/spinner.module';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    CalculatorSidenavComponent,
    UserVenuesComponent,
    VenueLogoComponent,
    NoAccessComponent
  ],
  imports: [SharedModule,
            MaterialModule,
            LayoutRoutingModule,
            MenuModule,
            ImageOrDefaultIconModule,
            PipesModule,
            CartModule,
            TabsModule,
            NotificationTooltipModule,
            DirectivesModule,
            SpinnerModule
          ],
})
export class LayoutModule {}
