<div class="rs-quantity-select d-flex align-items-center">
  <button
    *ngIf="changeable"
    mat-icon-button
    [disabled]="quantity === 0 || disabled"
    [disableRipple]="true"
    (click)="decrease()"
  >
    <mat-icon>remove</mat-icon>
  </button>
  <span>{{ quantity ? quantity : 0 }}</span>
  <!-- TODO: probably we need to allow user to input the quantity-->
  <button
    *ngIf="changeable"
    mat-icon-button
    [disabled]="maxValue && quantity >= maxValue || disabled"
    [disableRipple]="true"
    (click)="increase()"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
