import { NewCardToAdd, Card, CardToEdit } from '../shared/models/card.model';

export class GetWalletInitData {
  static readonly type = '[Wallet] Get Init Data';
}

export class AddCard {
  static readonly type = '[Card] Add';

  constructor(public newCardToAdd: NewCardToAdd) {}
}

export class DeleteCard {
  static readonly type = '[Card] Delete';

  constructor(public cardId: string) {}
}

export class EditCard {
  static readonly type = '[Card] Edit';

  constructor(
    public card: Card,
    public cardToEdit: CardToEdit
  ) {}
}

export class ClearWallet {
  static readonly type = '[Wallet] Clear';
}
