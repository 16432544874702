import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ImageOrDefaultIconComponent } from './image-or-default-icon.component';

@NgModule({
            declarations: [ImageOrDefaultIconComponent],
            imports     : [
              CommonModule,
              MatIconModule
            ],
            exports     : [ImageOrDefaultIconComponent]
          })
export class ImageOrDefaultIconModule {
}
