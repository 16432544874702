import { Error } from '../models/error.model';

export const ERRORS: Error[] = [
  {
    code: 4701009,
    errorTitle: 'errors.credentials.title',
    errorContent: 'errors.credentials.content',
  },
  {
    code: 4701011,
    errorTitle: 'errors.credentials.title',
    errorContent: 'errors.user-blocked'
  }
];

export const ERROR_STATUSES = {
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  SERVER_EROR: 500,
  UNKNOWN: 0,
};


export const LOGIN_ERROR_CODES = [
  {
    code: 4701009,
    shownMessage: 'errors.credentials.content'
  }
];
