import { Component, Input } from '@angular/core';

import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { CartState } from 'src/app/ngxs/cart.state';
import { CartValueCheck } from '../cart.models';

@Component({
  selector: 'rs-cart-total',
  templateUrl: './cart-total.component.html',
  styleUrls: ['./cart-total.component.scss'],
})
export class CartTotalComponent {
  @Input() check: CartValueCheck;

  @Select(CartState.getTotalPrice())
  totalPrice$: Observable<number>;
}
