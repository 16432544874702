<div class="rs-cart">
  <div
    class="rs-cart__header d-flex align-items-center justify-content-between"
  >
    <div class="rs-cart__title">
      <p>
        <span class="rs-cart__name">{{'common.cart' | translate}}</span>
        <span *ngIf="(itemsAmount$ | async); let itemsLength">
          - {{itemsLength}} {{(itemsLength === 1 ? "common.item" : "common.items") | translate}}
        </span>
      </p>
    </div>

    <button mat-icon-button (click)="close()">  <!--TODO: add alt text and hide icon-->
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="rs-cart__items-wrapper">
    <div
      class="rs-cart__items"
      *ngFor="let item of (items$ | async)"
    >
      <rs-cart-item [item]="item" [disabled]="disabled$ | async"></rs-cart-item>
    </div>
  </div>

  <rs-cart-total [check]="check$ | async "></rs-cart-total>

  <div class="rs-cart__action">
    <button
      mat-flat-button
      color="primary"
      (click)="goToCheckout()"
    >
      {{"order.Go_to_Checkout" | translate}}
    </button>
  </div>
</div>
