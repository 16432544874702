import { NgModule } from '@angular/core';
import { SignInComponent } from './sign-in.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { FooterModule } from 'src/app/shared/components/footer/footer.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SignInComponent],
  imports: [
    SharedModule,
    MaterialModule,
    FooterModule,
    RouterModule,
  ],
  exports: [SignInComponent],
})
export class SignInModule {}
