import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared.module';
import { TabsComponent } from './tabs.component';

@NgModule({
  declarations: [TabsComponent],
  imports: [SharedModule, MaterialModule, RouterModule],
  exports: [TabsComponent],
})
export class TabsModule {}
