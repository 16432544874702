import {Injectable} from '@angular/core';

import {tap} from 'rxjs/operators';

import {Action, Selector, State, StateContext, Store} from '@ngxs/store';

import {Navigate} from '@ngxs/router-plugin';

import {TranslateService} from '@ngx-translate/core';

import {
  SignIn,
  SignInError,
  Logout,
  SaveAuthenticationData,
  SwitchContextByTenantId,
  SaveVenueAuthenticationData, SaveRefreshToken,
} from './authentication.actions';
import {Spinner} from '../shared/classes/spinner.class';
import {PAGES} from '../shared/constants/pages';
import {LOGIN_ERROR_CODES} from '../shared/constants/errors';
import {createCopy} from '../shared/utils/create-object-copy';
import {getErrorContentByCode, getErrorTitleByCode} from '../shared/utils/error';
import {AuthenticationData} from '../shared/models/authentication-data.model';
import {AuthStateModel} from '../shared/models/authentication-state.model';
import {AuthenticationService} from '../shared/services/authentication.service';
import {NotificationService} from '../shared/services/notification.service';
import {GetProfileAddresses, GetUserProfileData, GetUserVenues} from './user.actions';


const DEFAULT_AUTH_STATE_DATA: AuthStateModel = {
  isAuthenticated: false,
  accessToken: null,
  expiresIn: null,
  refreshToken: null,
  tokenType: null,
  signInError: '',
  venueAccessToken: null,
  venueRefreshToken: null,
  venueTokenIsLoading: false
};

@State<AuthStateModel>({
  name: 'authentication',
  defaults: createCopy(DEFAULT_AUTH_STATE_DATA) as AuthStateModel,
})
@Injectable()
export class AuthenticationState extends Spinner {
  constructor(
    private readonly authService: AuthenticationService,
    protected readonly store: Store,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
  ) {
    super(store);
  }

  @Selector()
  static isAuth(state: AuthStateModel): boolean {
    return state.isAuthenticated;
  }

  @Selector()
  static signInError(state: AuthStateModel): string {
    return state.signInError;
  }

  @Selector()
  static accessToken(state: AuthStateModel): string {
    return state.accessToken;
  }

  @Selector()
  static venueToken(state: AuthStateModel): string {
    return state.venueAccessToken;
  }

  @Selector()
  static venueTokenIsLoading(state: AuthStateModel): boolean {
    return state.venueTokenIsLoading;
  }

  @Selector()
  static refreshToken(state: AuthStateModel): string {
    return state.refreshToken;
  }

  @Action(SignIn)
  signIn(
    {patchState}: StateContext<AuthStateModel>,
    {authentication}: SignIn
  ) {
    this.showSpinner();

    return this.authService.signIn(authentication).pipe(
      tap({
        next: (authenticationData: AuthenticationData) => {
          if (authenticationData) {
            patchState({
              isAuthenticated: !!authenticationData.accessToken,
              accessToken: authenticationData.accessToken,
              refreshToken: authenticationData.refreshToken,
              tokenType: authenticationData.tokenType,
              expiresIn: authenticationData.expiresIn,
              signInError: '',
            });
            this.store.dispatch(new Navigate([PAGES.MARKETPLACE]));
          }
        },
        error: (error) => {
          this.hideSpinner();
          const message = this.getLoginErrorMessageByCode(error.error.code);

          if (message) {
            this.store.dispatch(new SignInError(message));
            return;
          }

          if (getErrorTitleByCode(error.error.code)) {
            this.notificationService.showErrorMessage(
              this.translateService.instant(getErrorTitleByCode(error.error.code)),
              this.translateService.instant(getErrorContentByCode(error.error))
            );
          } else {
            this.notificationService.showErrorMessage(error?.statusText, error?.error?.message);
          }
        }
      })
    );
  }

  @Action(SignInError)
  setSignInError(
    {patchState}: StateContext<AuthStateModel>,
    action: SignInError
  ) {
    patchState({signInError: action.signInError});
  }

  @Action(SaveAuthenticationData)
  saveAuthenticationData(
    {patchState}: StateContext<AuthStateModel>,
    {authentication}: SaveAuthenticationData
  ) {
    patchState({
      isAuthenticated: !!authentication.accessToken,
      accessToken: authentication.accessToken,
      refreshToken: authentication.refreshToken,
      expiresIn: authentication.expiresIn,
    });
  }

  @Action(SaveVenueAuthenticationData)
  SaveVenueAuthenticationData(
    {patchState}: StateContext<AuthStateModel>,
    {authentication}: SaveVenueAuthenticationData
  ) {
    patchState({
      venueAccessToken: authentication.accessToken,
      venueRefreshToken: authentication.refreshToken
    });
  }

  @Action(SaveRefreshToken)
  SaveRefreshToken(
    {patchState}: StateContext<AuthStateModel>,
    {token}: SaveRefreshToken
  ) {
    patchState({
      refreshToken: token
    });
  }

  @Action(Logout)
  logout({setState}: StateContext<AuthStateModel>) {
    setState(createCopy(DEFAULT_AUTH_STATE_DATA) as AuthStateModel);
    this.store.dispatch(new Navigate([PAGES.SIGN_IN]));
  }

  @Action(SwitchContextByTenantId)
  switchContextByTenantId(
    {patchState}: StateContext<AuthStateModel>,
    {tenantId}: SwitchContextByTenantId
  ) {
    patchState({venueTokenIsLoading: true});

    return this.authService.switchContext(tenantId).pipe(
      tap({
        next: (data) => {
          this.store.dispatch([new GetUserProfileData()]);

          patchState({
            venueAccessToken: data?.accessToken,
            venueRefreshToken: data?.refreshToken,
            venueTokenIsLoading: false
          });
        },
        error: () => patchState({venueTokenIsLoading: false})
      })
    );
  }

  private getLoginErrorMessageByCode(errorCode: number): string {
    const errorData = LOGIN_ERROR_CODES.find(({code}) => code === errorCode);

    return errorData ? errorData.shownMessage : '';
  }
}
