<form class="rs-address-form" [formGroup]="addressForm">
  <div *ngIf="invalid" class="rs-address-form__invalid-message">
    {{ "titles.invalid-address" | translate }}
  </div>
  <div class="rs-address-form__field mb-2">
    <label for="address1">{{ "placeholders.first-address" | translate }}</label>
    <mat-form-field appearance="outline" floatLabel="always">
      <input
        matInput
        id="address1"
        placeholder="{{ 'placeholders.first-address' | translate }}"
        type="text"
        formControlName="address1"
      />
      <mat-error class="rs-app__validation-error" *ngIf="addressForm.get('address1').hasError('required')">
        {{ 'errors.validation.required' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="rs-address-form__field  mb-2">
    <label for="address2">{{ "placeholders.second-address" | translate }}</label>
    <mat-form-field appearance="outline" floatLabel="always">
      <input
        matInput
        id="address2"
        placeholder="{{ 'placeholders.second-address' | translate }}"
        type="text"
        formControlName="address2"
      />
    </mat-form-field>
  </div>
  <div class="rs-address-form__field  mb-2">
    <label for="city">{{ "placeholders.city" | translate }}</label>
    <mat-form-field appearance="outline" floatLabel="always">
      <input
        matInput
        id="city"
        placeholder="{{ 'placeholders.city' | translate }}"
        type="text"
        formControlName="city"
      />
      <mat-error class="rs-app__validation-error" *ngIf="addressForm.get('city').hasError('required')">
        {{ 'errors.validation.required' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="rs-address-form__field  mb-2">
    <label for="zipcode">{{ "placeholders.zip-code" | translate }}</label>
    <mat-form-field appearance="outline" floatLabel="always">
      <input
        matInput
        id="zipcode"
        placeholder="{{ 'placeholders.zip-code' | translate }}"
        type="text"
        formControlName="zipcode"
      />
      <mat-error class="rs-app__validation-error" *ngIf="addressForm.get('zipcode').hasError('required')">
        {{ 'errors.validation.required' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="rs-address-form__field  mb-2">
    <label for="state">{{ "placeholders.state" | translate }}</label>
    <mat-form-field appearance="outline" floatLabel="always">
      <input
        matInput
        id="state"
        placeholder="{{ 'placeholders.state' | translate }}"
        type="text"
        formControlName="state"
      />
      <mat-error class="rs-app__validation-error" *ngIf="addressForm.get('state').hasError('required')">
        {{ 'errors.validation.required' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
</form>
