<div class="rs-layout">
  <div class="rs-layout__header">
    <rs-header></rs-header>
  </div>

  <ng-container *ngIf="venueTokenIsLoading$ | async; else mainContent">
    <rs-spinner></rs-spinner>
  </ng-container>

  <ng-template #mainContent>
    <div class="rs-layout__content" *ngIf="venueToken$ | async">
      <mat-sidenav-container class="sidenav-container">
        <rs-tabs></rs-tabs>

        <ng-container *ngIf="(cartNotification$ | async); let cartNotification">
          <rs-notification-tooltip *ngIf="cartNotification?.isShow">
            <div class="rs-cart-item" *ngFor="let item of cartNotification.items">
              <div class="rs-cart-item__count rs-truncated">{{ item.quantity }}</div>
              <div class="rs-cart-item__image">
                <rs-image-or-default-icon
                  iconName="important_devices"
                  [photo]="item.photo"
                ></rs-image-or-default-icon>
              </div>
              <div class="rs-cart-item__name">
                {{ item.name }}
              </div>
          </div>
          </rs-notification-tooltip>
        </ng-container>

        <div class="rs-layout__main-content" [class]="MAIN_CONTENT_CLASS" #mainContent>
          <router-outlet></router-outlet>
        </div>
        <mat-sidenav
          *ngIf="sideComponent$ | async; let sideComponent"
          [opened]="sideVisibility$ | async"
          mode="over"
          position="end"
          class="rs-right-side-container"
          [class.large]="sideComponent === SidenavComponent.UserVenues"
          (closedStart)="close()"
        >
          <ng-container [ngSwitch]="sideComponent">
            <rs-calculator-sidenav
              *ngSwitchCase="SidenavComponent.Calculator"
            ></rs-calculator-sidenav>
            <rs-cart
              *ngSwitchCase="SidenavComponent.Cart"
            ></rs-cart>
            <rs-user-venues
              *ngSwitchCase="SidenavComponent.UserVenues"
            ></rs-user-venues>
          </ng-container>
        </mat-sidenav>
      </mat-sidenav-container>
    </div>
  </ng-template>
</div>
