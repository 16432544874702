import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';

import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { COMMON_CONSTANTS } from '../constants/common';
import { HttpLoaderFactory } from '../utils/http';
import { environment as env } from '../../../environments/environment';
import { AppState } from '../../ngxs/app.state';
import { AuthenticationState } from 'src/app/ngxs/authentication.state';
import { WalletState } from 'src/app/ngxs/wallet.state';
import { CheckoutState } from 'src/app/ngxs/checkout.state';
import { UserProfileState } from 'src/app/ngxs/user.state';
import { DeviceState } from 'src/app/ngxs/device.state';
import { CartState } from 'src/app/ngxs/cart.state';
import { OrderHistoryState } from 'src/app/ngxs/order-history.state';
import { AddressesState } from 'src/app/ngxs/address.state';


@NgModule({
  imports: [
    NgxsModule.forRoot(
      [
        AppState,
        AuthenticationState,
        WalletState,
        UserProfileState,
        CheckoutState,
        DeviceState,
        CartState,
        OrderHistoryState,
        AddressesState
      ],
      { developmentMode: !env.production }
    ),
    NgxsStoragePluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: COMMON_CONSTANTS.DEFAULT_LANGUAGE,
    }),
    SimpleNotificationsModule.forRoot({
      maxStack: 1,
    }),
  ],
  exports: [TranslateModule],
})
export class PluginsModule {}
