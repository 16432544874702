import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs';

import {
  addVersionsForMSPath,
  replaceAliasToRealApiPath
} from '../utils/endpoint';
import { ENDPOINTS_USE_MOCK_DATA } from '../constants/mock';
import { dematerialize, materialize } from 'rxjs/operators';
import { simulateApiAnswer } from '../services/fake-api-logic';

@Injectable({providedIn: 'root'})
export class EndpointInterceptor implements HttpInterceptor {
  static handleFakeAPIRoute(request: HttpRequest<any>, next: HttpHandler) {
    return simulateApiAnswer(request) || next.handle(request);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let {url} = request;

    if (ENDPOINTS_USE_MOCK_DATA.findIndex(endpoint => (url.indexOf(endpoint) > -1 && endpoint.length === url.length)) > -1) {
      return EndpointInterceptor.handleFakeAPIRoute(request, next)
                                .pipe(materialize())
                                .pipe(dematerialize());
    }

    url = addVersionsForMSPath(url);
    url = replaceAliasToRealApiPath(url);

    request = request.clone({url});

    return next.handle(request);
  }
}
