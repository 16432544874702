import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { API_ALIASES } from '../constants/endpoint';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseApiService {
  
  constructor(protected readonly http: HttpClient) {
    super(API_ALIASES.apiUrlAlias, http);
  }

}

@Injectable({providedIn: 'root'})
export class PayService extends BaseApiService {

  constructor(protected readonly http: HttpClient) {
    super(API_ALIASES.paymentApiUrlAlias, http);
  }

}
