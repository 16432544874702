import { Component, Input } from '@angular/core';

import { Image } from 'src/app/shared/models/image.model';

@Component({
  selector: 'rs-venue-logo',
  templateUrl: './venue-logo.component.html',
  styleUrls: ['./venue-logo.component.scss'],
})
export class VenueLogoComponent {
  @Input() size: string;
  @Input() logo: Image;
}
