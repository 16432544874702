import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../../material.module';

import { ItemQuantityComponent } from './item-quantity.component';


@NgModule({
  declarations: [ItemQuantityComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [ItemQuantityComponent]
})
export class ItemQuantityModule { }
