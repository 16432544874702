import { Component, Input } from '@angular/core';
import { fadeIn } from '../../animations/fade-in.animation';

@Component({
  selector: 'rs-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [fadeIn],
})
export class MenuComponent {
  @Input() icon: string;
  @Input() iconClass: string;

  public isOpen = false;

  public hide(): void {
    this.isOpen = false;
  }

  public open($event: MouseEvent): void {
    this.isOpen = true;
    $event.stopPropagation();
  }
}
