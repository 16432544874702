import { Component, Input } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Breadcrumb } from '../../models/breadcrumb.model';

@Component({
  selector: 'rs-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  @Input() breadcrumbs: Breadcrumb[];
  @Input() color: string;

  constructor(private store: Store) {}

  public onLinkClick(link: string, i: number): void {
    if (i === this.breadcrumbs.length - 1) {
      return;
    }

    this.store.dispatch(new Navigate([link]));
  }
}
