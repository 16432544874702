import { createCopy } from '../utils/create-object-copy';
import { DeviceItem } from '../models/item';
import { RecommendedDevice } from 'src/app/modules/marketplace/models/device.model';
import { ENDPOINTS } from './endpoint';
import { CartEntry, CartItem, CartValue, CartValueCheck, ItemDetails } from 'src/app/modules/cart/cart.models';
import { Currency, Payment, PaymentInstalment } from '../models/payment.model';
import { OrderStatus } from 'src/app/shared/enums/order-statuses.enum';
import { PaymentCard } from '../enums/payment-card.enum';
import { CurrencySymbol, CurrencyType } from '../enums/currency.enum';
import { Order, OrderCheckEntry, OrderItem } from '../models/order.model';
import { PaymentFrequency, TransactionType } from '../enums/payment-frequency';
import { getEndpointForCartId, getEndpointForOrderId, isCurrentUrl } from '../utils/endpoint';

// start: imports
const MOCK_ENTITY: string = 'devices_entity';
// TODO: https://dashboard.rockspoon.io/#/catalogs/serviceDetailView/service/search

const MOCK_CART_ID: string = 'mock_cart_id_1'; // TODO: cart id changes everytime when new order
const MOCK_ORDER_ID: string = 'mock_order_id_1';
const MOCK_VENUE_ID: string = 'mock_venue_id_1';
const MOCK_ROCKSPOON_VENUE_ID: string = 'mock_rockspoon_venue_id';
// TODO: for getting devices will be one venueId (Roskpoon)

const MOCK_DEVICE_CATEGORY: string = 'device';
// TODO: uses in model from https://dashboard.rockspoon.io/#/catalogs/serviceDetailView/service/catalog
// GET /consumer/item/{itemId}
// ends: imports

// start: without import
const MOCK_PRICES: number[] = [4010, 23900, 12300, 43500, 123500, 94800, 1000, 1100, 2300];
const itemsIds = Array.from({length: 8}, (v, index) => 'ITEM_ID_' + index);
const itemsNames = [
  'Tablet POS all-in-one solution for all restaurants',
  'Turn tables faster with Mobile POS',
  'Payment Tablet M70 Ingenico',
  'Resilient Receipt Printer easy to setup',
  'Quick inventory updates with Bluetooth Barcode Scanner',
  'Always connected Internet Router',
  'Smart Cash Drawer to protect your business',
  '32 Pack Thermal Receipt Paper 3-1/8\' X 230'
];
const metas: { [key: string]: string }[] = [
  {
    Dimension: '194 * 110 * 10.6 mm',
    Weight   : '270 gr',
    Color    : 'Black',
    Meta     : 'ITEM_ID_1'
  },
  {
    'Screen Type'      : 'WSVGA',
    'Screen Resolution': '600 * 1024 Pixels',
    'Screen Size'      : '7.0 Inch',
    Meta               : 'ITEM_ID_2'
  },
  {
    CPU               : 'Quad Core 1.3 GHz',
    RAM               : '1 GB',
    'Internal Memory' : '8 GB',
    'Eksternal Memory': 'Micro SD Up To 64 GB',
    Battery           : '2900 mAh',
    Meta              : 'ITEM_ID_3'
  },
  {
    'Super AMOLED Display': '',
    ''                    : 'S-Pen Included',
    'Display Size'        : '10.1',
    'Display Resolution'  : '1920 x 1200',
    Audio                 : '',
    Meta                  : 'ITEM_ID_4'
  },
  {
    Network     : 'GSM/3G/HSDPA/4G',
    Connectivity: 'Bluetooth, WifiB',
    Sensor      : 'GPS, 3D Gravity Sensor, Gesture Smart Access',
    Features    : 'Video Call/Chat, Support OTG, Fingerprint',
    Meta        : 'ITEM_ID_5'
  },
  {
    Meta: 'ITEM_ID_6'
  },
  {
    Meta: 'ITEM_ID_7'
  },
  {
    Meta: 'ITEM_ID_8'
  }
];

const itemsPhotos = Array.from({length: 8}, (v, index) => '../../../../assets/images/image-' + (index + 1) + '.png');

const MOCK_CURRENCY: Currency = {
  symbol : CurrencySymbol.USD,
  type   : CurrencyType.USD,
  decimal: 2
};

const getPrice = (): number => {
  const randomIndex = Math.round(Math.random() * MOCK_PRICES.length);
  return MOCK_PRICES[randomIndex] * 100;
};
const getSizeAndPrice = (id: string) => {
  const itemMenuAttribute = {
    index          : 'SIZE_AND_PRICE_ID_' + id,
    isVariablePrice: true,
    menuId         : 'MENU_ID' + id,
    menuSectionId  : 'MENU_ID' + id,
    menuVId        : 'MENU_ID' + id,
    menuType       : 'standard',
    price          : getPrice()
  };

  return {
    id            : 'SIZE_AND_PRICE_ID' + id,
    menuAttributes: [createCopy(itemMenuAttribute)],
    name          : 'SIZE_AND_PRICE_NAME' + id
  };
};

const MOCK_ITEM_DEVICE: DeviceItem = {
  id            : '',
  venueId       : MOCK_ROCKSPOON_VENUE_ID,
  title         : '',
  photos        : [{
    id         : '',
    contentType: '',
    resolutions: {
      low    : {},
      high   : {},
      default: {},
    }
  }],
  meta          : {},
  sizesAndPrices: [{
    id            : '',
    menuAttributes: [],
    name          : ''
  }],
  isAvailable: false
};
// end: without import

// start: imports
const MOCK_ITEM_DEVICES_ARRAY: DeviceItem[] = itemsIds.map((itemId: string, index: number) => {
  const device: DeviceItem = createCopy(MOCK_ITEM_DEVICE) as DeviceItem;
  device.meta = metas[index];
  device.id = itemId;
  device.title = itemsNames[index];
  const resolution = index % 3 === 0 ? 'high' :
                     index % 3 === 1 ? 'low' : 'default';
  device.photos[0].resolutions[resolution].url = itemsPhotos[index];
  device.sizesAndPrices = [getSizeAndPrice(itemId)];

  return device;
});

const getMockDeviceById = (itemId: string): DeviceItem | undefined => {
  return MOCK_ITEM_DEVICES_ARRAY.find(item => item.id === itemId);
};

const MOCK_RECOMMENDED_DEVICES_ARRAY: RecommendedDevice[] = itemsIds.slice(0, 5).map(
  (deviceId, index) => {
    const device: RecommendedDevice =
      createCopy(MOCK_ITEM_DEVICES_ARRAY.find(item => item.id === deviceId)) as RecommendedDevice;
    device.count = index + 1;
    return device;
  }
);

const MOCK_CART_VALUE: CartValue = {
  id: MOCK_CART_ID,
  orderType: 'dine-in',
  venueId: MOCK_VENUE_ID,
  items: [],
  check: {entries: []} as CartValueCheck,
};

const getMockCartValue = (item: ItemDetails, currentValue: CartValue) => {
  const deviceItem = getMockDeviceById(item.itemId);
  currentValue = createCopy(currentValue ?? MOCK_CART_VALUE);

  const cartItem: CartItem = {
    orderItemId: item.itemId + 'orderId',
    description: 'description',
    createdAt: 'string',
    itemId: item.itemId,
    name: deviceItem.title,
    bundleItems: [],
    photo: deviceItem.photos[0]?.resolutions?.high?.url,
    // price: deviceItem.sizesAndPrices
  };

  for (let index = 0; index < item.quantity; index++) {
    currentValue.items.push(cartItem);
  }

  if (!currentValue.check.entries.find(({orderItemId}) => cartItem.orderItemId === orderItemId)) {
    const devicePrice = getMockDeviceById(item.itemId).sizesAndPrices[0].menuAttributes[0].price;
    currentValue.check.entries.push({
      orderItemId: cartItem.orderItemId,
      netPrice: devicePrice,
      grossPrice: devicePrice * 1.085 // added 8.5% of taxes
    } as CartEntry);
  }

  return currentValue;
};

const endpointsUseMockData: string[] = [
  // ENDPOINTS.catalog.devicesList,
  // ENDPOINTS.catalog.recommendations,
  // ENDPOINTS.order.createCart,
  // ENDPOINTS.order.getAllOrders,
  ENDPOINTS.wallet.orderPaymentInformation,
  getEndpointForCartId(ENDPOINTS.order.commitCart, MOCK_CART_ID),
  getEndpointForOrderId(ENDPOINTS.order.placeAnOrder, MOCK_ORDER_ID)
];

const ENDPOINTS_USE_MOCK_DATA: string[] = endpointsUseMockData.map(endpoint => {
  return (isCurrentUrl(endpoint, ENDPOINTS.wallet.orderPaymentInformation) ? '$pay/' : '$ms/') + endpoint.split('{')[0];
});

// Orders mocked data

// helders (consts, generators)

const getId = (prefix: string, id: number) => prefix + id;
// Orders and items amounts
const ORDERS_AMOUNT = 6;
const ORDER_ITEMS_AMOUNT = 8;
// IDs
const ADDRESS_ID = 'ADDRESS_ID_1';
const ORDER_ID_PRFIX = 'ORDER_ID_';
const CHECK_ID = 'CHECK_ID_1';
const VENUE_ID = 'VENUE_ID_1';
// Short IDs
const SHORT_ID = 1234;
// Order data
const CREATED_AT_DATE = '2021-04-08T18:43:19.613Z';
const SCHEDULED_FOR_DATE = new Date().toISOString();
const ORDER_ADDRESS = {
  address1: '228 Hamilton Avenue',
  address2: '',
  city: 'Palo Alto',
  country: 'United States',
  state: 'California',
  id: ADDRESS_ID,
  instructions: '',
  name: '',
  region: 'California',
  status: '',
  timezone: 'America/Los_Angeles',
  zipcode: '94301'
};
const ORDER_USD_CURRENCY: Currency = {
  decimal: 2,
  symbol: CurrencySymbol.USD,
  type: CurrencyType.USD
};
const ORDER_PRICE = 4500;
const ORDER_STATUSES = [OrderStatus.PENDING, OrderStatus.OPEN, OrderStatus.DRAFT, OrderStatus.CLOSED];
// Item data
const ITEM_DESCRIPTIONS = [
  'description 1',
  'description 2',
  'description 3',
  'description 4',
  'description 5',
  'description 6',
  'description 7',
  'description 8',
];
const ITEM_PRICES = Array.from({length: ORDER_ITEMS_AMOUNT}, (v, index) => (index + 1) * 100);
const ITEM_ENTRY_ID_PREFIX = 'ITEM_ENTRY_ID_';
const ITEM_ID_PREFIX = 'ITEM_ID_';
const ORDER_ITEM_ID_PREFIX = 'ORDER_ITEM_ID_PREFIX_';
const SOURCE_CHECK_ID_PREFIX = 'SOURCE_CHECK_ID_PREFIX_';
const MENU_NAMES = [
  'Networking Products',
  'Computers & Tablets',
  'Networking Products',
  'Computers & Tablets',
  'Computers & Tablets',
  'Cables & Interconnects',
  'Cables & Interconnects',
  'Monitors',
  'Monitors'
];
const ITEM_NAMES = [
  'NETGEAR Nighthawk Smart Wi-Fi Router, R6700 - AC1750',
  'Samsung Galaxy Tab A 8.4", 32GB',
  'NETGEAR 5-Port Gigabit Ethernet Unmanaged Switch',
  'HP Chromebook 14-inch HD Laptop, Intel Celeron N4000, 4 GB RAM, 32 GB eMMC, Chrome',
  'HP Chromebook 14-inch HD Laptop, Intel Celeron N4000, 4 GB RAM, 32 GB eMMC, Chrome',
  'Amazon Basics RJ45 Cat-6 Ethernet Patch Internet Cable - 5 Feet',
  'Amazon Basics RJ45 Cat-6 Ethernet Patch Internet Cable - 5 Feet',
  'LG 27UN850-W 27 Inch Ultrafine UHD (3840 x 2160) IPS Display with VESA DisplayHDR 400',
  'LG 27UN850-W 27 Inch Ultrafine UHD (3840 x 2160) IPS Display with VESA DisplayHDR 400'
];
const ITEM_PHOTOS = [
  'https://images-na.ssl-images-amazon.com/images/I/61FA9BbugzL._AC_SL1500_.jpg',
  'https://images-na.ssl-images-amazon.com/images/I/71jDNnx0BxL._AC_SL1500_.jpg',
  'https://images-na.ssl-images-amazon.com/images/I/814lMQ5y9GL._AC_SL1500_.jpg',
  'https://images-na.ssl-images-amazon.com/images/I/91shKLxoedL._AC_SL1500_.jpg',
  'https://images-na.ssl-images-amazon.com/images/I/91shKLxoedL._AC_SL1500_.jpg',
  'https://images-na.ssl-images-amazon.com/images/I/61Hewd49NFL._AC_SL1500_.jpg',
  'https://images-na.ssl-images-amazon.com/images/I/61Hewd49NFL._AC_SL1500_.jpg',
  'https://images-na.ssl-images-amazon.com/images/I/81kVFBp13RL._AC_SL1500_.jpg',
  'https://images-na.ssl-images-amazon.com/images/I/81kVFBp13RL._AC_SL1500_.jpg'
];
const ITEM_SECTION_NAME = [
  'Networking Products',
  'Computers & Tablets',
  'Networking Products',
  'Computers & Tablets',
  'Computers & Tablets',
  'Cables & Interconnects',
  'Cables & Interconnects',
  'Monitors',
  'Monitors'
];

const MOCK_ORDER_ITEM_ENRIES: OrderCheckEntry[] = Array.from({length: ORDER_ITEMS_AMOUNT}, (v, index) => ({
  description: ITEM_DESCRIPTIONS[index % ITEM_DESCRIPTIONS.length],
  grossPrice: ITEM_PRICES[index % ITEM_PRICES.length],
  id: getId(ITEM_ENTRY_ID_PREFIX, index + 1),
  itemId: getId(ITEM_ID_PREFIX, index + 1),
  menuName: MENU_NAMES[index % MENU_NAMES.length],
  name: ITEM_NAMES[index % ITEM_NAMES.length],
  netPrice: 0,
  orderItemId: getId(ORDER_ITEM_ID_PREFIX, index + 1),
  parent: null,
  photo: ITEM_PHOTOS[index % ITEM_PHOTOS.length],
  sectionName: ITEM_SECTION_NAME[index % ITEM_SECTION_NAME.length],
  sourceCheckId: getId(SOURCE_CHECK_ID_PREFIX, index + 1),
  type: 'order_item',
  unitPrice: ITEM_PRICES[index % ITEM_PRICES.length],
  quantity: 1
}));

const MOCK_ORDERS: (Order | any)[] = Array.from({length: ORDERS_AMOUNT}, (v, index) => ({
  address: ORDER_ADDRESS,
  checks: [
    {
      checkId: CHECK_ID,
      checkShortId: SHORT_ID,
      currency: CurrencyType.USD,
      entries: MOCK_ORDER_ITEM_ENRIES,
      grossTotal: 1,
      netTotal: 1,
      orderShortId: index + 1
    }
  ],
  createdAt: CREATED_AT_DATE,
  itemsCount: 1,
  orderId: getId(ORDER_ID_PRFIX, index + 1),
  scheduledFor: SCHEDULED_FOR_DATE,
  shortId: SHORT_ID,
  status: ORDER_STATUSES[index % ORDER_STATUSES.length],
  total: {
    value: ORDER_PRICE,
    currency: ORDER_USD_CURRENCY
  },
  venueId: VENUE_ID
}));

// payment
const MOCK_ORDER_PAYMENT: (Payment | any)[] = [{
  amount: {
    amount: 4500,
    symbol: CurrencySymbol.USD
  },
  cardInfo: {
    cardBrand: PaymentCard.VISA,
    cardHolderName: 'Hermes E Renato',
    cvv: '123',
    redactedCardNumber: '123456******1234',
    tokenId: 'TOKEN_ID_1',
    type: 'credit'
  },
  changeFor: {
    amount: 1100,
    symbol: CurrencySymbol.USD
  },
  checkId: '606f4ec732e2a81405dfa05d',
  consumerProfileId: '605122e7808e60bc715d910d',
  createdAt: '2021-04-08T18:43:24Z',
  details: [{
    authorization_data: {
      auth_amount_in_cents: 521609,
      authorization_method: '521609',
      clerk_display: 'APPROVAL',
      pos_entry_mode: 'Keyed',
      requested_amount_in_cents: 1,
      response_code: 200,
      response_type: 'Approved'
    },
    card: {
      card_type: PaymentCard.VISA,
      is_generate_unique_card_identifier: false,
    },
    client_transaction_id: '09d4942e-2f48-4ffa-8dca-3ce3a7d45424',
    currency: {
      code: CurrencyType.USD,
      exponent: 2,
      iso: 840
    },
    device_time_stamp: '20210408184323',
    device_time_zone: 'America/New_York',
    is_offline: true,
    mcm_time_stamp: '20210408184324',
    mcm_time_zone: 'America/New_York',
    original_sequence_number: 0,
    original_transaction_id: '0',
    payment_source: 'MOBILE',
    processor_time_stamp: '20210408184324',
    processor_time_zone: 'America/New_York',
    refundable_amount_in_cents: 1,
    sequence_number: 4712,
    transaction_guid: '8ed2196d-5ffe-4edd-83f9-0e624eddf633',
    transaction_id: '178808756',
    transaction_type: 'CreditSale',
  }],
  disputes: [{
    amount: {
      amount: 1100,
      symbol: CurrencySymbol.USD
    },
    date: '2021-04-08T18:37:23.903Z',
    description: 'disputes description',
    id: 'disputes id',
  }],
  employeeProfileId: '5ebd9c9aa438c1e645589b72',
  externalId: 'externalId',
  fee: {
    amount: 1100,
    symbol: CurrencySymbol.USD
  },
  id: '606f4eccbdc1ea4c5641f8c7',
  isCashTips: false,
  merchantId: '5f3ee207b51b18132ef58d48',
  method: 'cardnotpresent',
  orderId: '606f4ec732e2a81405dfa059',
  orderShortId: '2042',
  processorId: 'processorId',
  refunds: [{
    amount: {
      amount: 1100,
      symbol: CurrencySymbol.USD
    },
    date: '2021-04-08T18:37:23.904Z',
    description: 'ITEM_NOT_DELIVERED',
    id: 'refunds id',
  }],
  serviceCharge: {
    amount: 1100,
    symbol: CurrencySymbol.USD
  },
  serviceType: 'dine-in',
  shortId: '521609',
  status: 'captured',
  tax: {
    amount: 500,
    symbol: CurrencySymbol.USD
  },
  tip: {
    amount: 0,
    symbol: CurrencySymbol.USD
  },
  total: {
    amount: 4000,
    symbol: CurrencySymbol.USD
  }
}];

// payment transactions
const TRANSACTIONS_AMOUNT = 4;
const PAYMENT_SUM = 4500;
const PAYMENT_PER_TRANSACTION = 375;

const MOCK_PAYMENT_INSTALMENT: PaymentInstalment[]= [{
  id: 'INSTALMENT_ID_1',
  dueDate: '2021-12-08T18:37:23.903Z',
  outstandingBalance: {
    amount: (PAYMENT_SUM - PAYMENT_PER_TRANSACTION * TRANSACTIONS_AMOUNT),
    symbol: CurrencySymbol.USD
  },
  total: {
    amount: PAYMENT_SUM,
    symbol: CurrencySymbol.USD
  },
  downpayment: {
    amount: 1000,
    symbol: CurrencySymbol.USD
  },
  totalPaymentAmount: 12,
  paymentFrequency: PaymentFrequency.MONTHLY,
  instalmentAmount: {
    amount: PAYMENT_PER_TRANSACTION,
    symbol: CurrencySymbol.USD
  },
  transactions: Array.from({length: TRANSACTIONS_AMOUNT}, (v, index) => ({
    id: `ID_0123456789${index}`,
    date: `2021-${((index + 1) < 10) ? ('0' + (index + 1)) : (index + 1)}-08T18:37:23.903Z`,
    instalmentAmount: {
      amount: PAYMENT_PER_TRANSACTION,
      symbol: CurrencySymbol.USD
    },
    paidAmount: {
      amount: PAYMENT_PER_TRANSACTION,
      symbol: CurrencySymbol.USD
    },
    type: TransactionType.SETTLEMENT
  }))
}];

export {
  MOCK_CART_ID,
  MOCK_ORDER_ID,
  MOCK_VENUE_ID,
  MOCK_ENTITY,
  getMockDeviceById,
  MOCK_RECOMMENDED_DEVICES_ARRAY,
  MOCK_ITEM_DEVICES_ARRAY,
  ENDPOINTS_USE_MOCK_DATA,
  getMockCartValue,
  MOCK_ORDERS,
  MOCK_ORDER_ITEM_ENRIES,
  MOCK_ORDER_PAYMENT,
  MOCK_PAYMENT_INSTALMENT
};
