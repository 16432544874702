import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationTooltipComponent } from './notification-tooltip.component';

@NgModule({
  declarations: [NotificationTooltipComponent],
  imports: [CommonModule],
  exports: [NotificationTooltipComponent]
})

export class NotificationTooltipModule { }
