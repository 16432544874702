import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { tap } from 'rxjs/operators';

import { State, Action, StateContext, Store, Selector } from '@ngxs/store';

import { TranslateService } from '@ngx-translate/core';

import {
  GetAddressesInitData,
  AddAddress,
  EditAddress,
  DeleteAddress,
  MakeAddressAsDefault,
  OpenChooseDefaultAddressDialog
} from './address.actions';
import { UserProfileAddress } from '../shared/models/address.model';
import { Spinner } from '../shared/classes/spinner.class';
import { createCopy } from '../shared/utils/create-object-copy';
import { UserService } from '../shared/services/user.service';
import { NotificationService } from '../shared/services/notification.service';
import { ChooseDefaultAddressDialogComponent } from '../shared/components/choose-default-address-dialog/choose-default-address-dialog.component';
import { DIALOG_PARAMETERS } from '../shared/constants/common';
import { ChooseDefaultAddressDialogModule } from '../shared/components/choose-default-address-dialog/choose-default-address-dialog.module';

interface AddressesStateModel {
  addresses: UserProfileAddress[];
  normalizedAddress: UserProfileAddress;
}

@State<AddressesStateModel>({
                              name    : 'address',
                              defaults: {
                                addresses        : [],
                                normalizedAddress: null
                              }
                            })
@Injectable()
export class AddressesState extends Spinner {
  @Selector()
  static defaultAddress(state: AddressesStateModel): UserProfileAddress {
    return state.addresses.find(address => address.isDefault);
  }

  private chooseDefaultAddressDialogRef: MatDialogRef<ChooseDefaultAddressDialogComponent>;

  constructor(protected readonly store: Store,
              protected readonly dialog: MatDialog,
              private   readonly ngZone: NgZone,
              private   readonly userService: UserService,
              private   readonly notificationService: NotificationService,
              private   readonly translate: TranslateService) {
    super(store);
  }

  @Action(GetAddressesInitData)
  getAddressesInitData({patchState}: StateContext<AddressesStateModel>) {
    return this.userService.getProfileAddresses()
               .pipe(
                   tap({
                         next    : (addresses: UserProfileAddress[]) => {
                                         this.checkDefaultAddresses(addresses);
                                         patchState({addresses});
                                         this.hideSpinner();
                         },
                         complete: () => this.hideSpinner()
                       })
               );
  }

  private isDefaultExisting(addresses: UserProfileAddress[]): boolean {
    return addresses && addresses.length && addresses.every(address => !address.isDefault);
  }

  @Action(AddAddress)
  addAddress({patchState, getState}: StateContext<AddressesStateModel>, action: AddAddress) {
    const addresses = createCopy(getState().addresses || []);
    const isDefaultExisting = this.isDefaultExisting(addresses);
    let address = createCopy(action.address);
    
    if (!address.country) {
      address.country = 'United States';
    }

    address.region = address.state;
    
    if (!isDefaultExisting) {
      address = createCopy(address) as UserProfileAddress;
      address.isDefault = true;
    }

    this.showSpinner();

    return this.userService.createNewProfileAddress(address)
               .pipe(
                   tap({
                         next: () => {
                           this.store.dispatch(new GetAddressesInitData());
                           
                           if (address.isDefault) {
                             addresses.forEach(currentAddress => {
                               currentAddress.isDefault = false;
                             } );
                           }

                           addresses.push(address);
                           
                           patchState({addresses});
                           
                           this.hideSpinner();
                         },
                         complete: () => this.hideSpinner()
                       })
               );
  }

  @Action(EditAddress)
  editAddress({patchState, getState}: StateContext<AddressesStateModel>, {address, addressId}: EditAddress) {
    this.showSpinner();

    return this.userService.updateProfileAddressById(addressId, address)
               .pipe(
                   tap({
                         next: () => {
                           this.store.dispatch(new GetAddressesInitData());
                           this.translate.get('common.your-changes-were-saved-successfully')
                               .subscribe((content: string) => {
                                 this.translate.get('common.success').subscribe((title: string) => {
                                   this.notificationService.showSuccessMessage(title, content);
                                 });
                               });
                           this.hideSpinner();
                         },
                       })
               );
  }

  @Action(DeleteAddress)
  deleteAddress({patchState, getState}: StateContext<AddressesStateModel>, {addressId}: DeleteAddress) {
    this.showSpinner();

    return this.userService.deleteProfileAddressById(addressId)
               .pipe(
                   tap({
                         next    : () => {
                           const addresses = [...getState().addresses];
                           const addressIndex = addresses.findIndex(address => address.id === addressId);

                           addresses.splice(addressIndex, 1);
                           this.checkDefaultAddresses(addresses);
                           
                           patchState({addresses});
                           
                           this.hideSpinner();
                         },
                         complete: () => this.hideSpinner()
                       })
               );
  }

  @Action(MakeAddressAsDefault)
  makeDefaultAsDefault({patchState, getState}: StateContext<AddressesStateModel>, action: MakeAddressAsDefault) {
    this.store.dispatch(new EditAddress(action.addressId, action.address))
      .subscribe(() => this.chooseDefaultAddressDialogRef.close());
  }

  @Action(OpenChooseDefaultAddressDialog)
  openChooseDefaultAddressDialog() {
    this.chooseDefaultAddressDialogRef = this.dialog.open(
      ChooseDefaultAddressDialogComponent,
      {
        ...DIALOG_PARAMETERS.productDialog,
        disableClose: true
      } as MatDialogConfig);
  }

  private checkDefaultAddresses(addresses: UserProfileAddress[]): void {
    if (addresses && addresses.length && addresses.every(address => !address.isDefault)) {
      this.store.dispatch(new OpenChooseDefaultAddressDialog());
    }
  }

}
function ChooseDefaultAddressComponent(ChooseDefaultAddressComponent: any, arg1: MatDialogConfig<any>) {
  throw new Error('Function not implemented.');
}

