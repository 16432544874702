<div class="rs-footer">
  {{ "footer.title" | translate }} |
  <span class="rs-footer__link" (click)="openFile('Terms_of_Use')">{{
    "common.term-of-use" | translate
  }}</span>
  |
  <div class="rs-footer__link" (click)="openFile('Privacy_Policy')">
    {{ "common.privacy-policy" | translate }}
  </div>
</div>
