import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { EditCard } from 'src/app/ngxs/wallet.actions';
import { Card } from 'src/app/shared/models/card.model';

@UntilDestroy()
@Component({
  selector: 'rs-select-default-card',
  templateUrl: './select-default-card.component.html',
  styleUrls: ['./select-default-card.component.scss'],
})
export class SelectDefaultCardComponent implements OnInit {
  @Select((state) => state.wallet.cards) cards$: Observable<Card[]>;

  public selectedCard: Card;

  constructor(
    private store: Store,
    private actions$: Actions,
    private dialogRef: MatDialogRef<SelectDefaultCardComponent>
  ) {}

  ngOnInit(): void {
    this.actions$
      .pipe(ofActionSuccessful(EditCard), untilDestroyed(this))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  public selectCard(card: Card): void {
    if (this.selectedCard?.id === card.id) {
      this.selectedCard = null;
    } else {
      this.selectedCard = card;
    }
  }

  public save(): void {
    const cardToEdit = {
      default: true,
      name: this.selectedCard.cardholderName,
      expirationDate: this.selectedCard.cardExpirationDate,
    };
    this.store.dispatch(new EditCard(this.selectedCard, cardToEdit));
  }
}
