import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SignInComponent } from './modules/sign-in/sign-in.component';
import { NotAuthenticationGuard } from './shared/guards/not-auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/marketplace' },
  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [NotAuthenticationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
