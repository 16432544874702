import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Select, Store } from '@ngxs/store';

import { MakeAddressAsDefault } from 'src/app/ngxs/address.actions';
import { UserProfileAddress } from '../../models/address.model';
import { createCopy } from '../../utils/create-object-copy';

@UntilDestroy()
@Component({
  selector: 'rs-choose-default-address-dialog',
  templateUrl: './choose-default-address-dialog.component.html',
  styleUrls: ['./choose-default-address-dialog.component.scss']
})
export class ChooseDefaultAddressDialogComponent implements OnInit {
  @Select(state => state.address.addresses) addresses$: Observable<UserProfileAddress[]>;

  public addresses: UserProfileAddress[];
  public selectedAddress: UserProfileAddress;
  public selectedAddressIndex: number;

  constructor(private readonly store: Store) {
  }

  ngOnInit() {
    this.initSubscriptions();
  }

  public addressIsActive(address: UserProfileAddress): boolean {
    return this.selectedAddress === address;
  }

  public save(): void {
    const address = createCopy(this.selectedAddress) as UserProfileAddress;

    address.isDefault = true;
    
    this.store.dispatch(new MakeAddressAsDefault(address.id, address));
  }

  public reactOnSelectAddress(addressIndex: number): void {
    this.selectedAddressIndex = addressIndex;
    this.selectedAddress = this.addresses[addressIndex];
  }

  private initSubscriptions(): void {
    this.addresses$
        .pipe(untilDestroyed(this))
        .subscribe(addresses => this.addresses = addresses || []);
  }

}
