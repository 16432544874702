import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import { ClearUserData, GetProfileAddresses, GetUserProfileData, GetUserVenues, SelectUserVenue } from './user.actions';
import { AuthenticationState } from './authentication.state';
import { User } from '../shared/models/user.model';
import { createCopy } from '../shared/utils/create-object-copy';
import { UserService } from '../shared/services/user.service';
import { UserProfileAddress } from '../shared/models/address.model';
import { Venue } from '../shared/models/venue.model';
import { SwitchContextByTenantId } from './authentication.actions';

interface UserStateModel {
  profile: User;
  addresses: UserProfileAddress[];
  venues: Venue[];
  selectedVenue: Venue;
}

const DEFAULT_USER_DATA: UserStateModel = {
  profile      : null,
  addresses    : [],
  venues       : [],
  selectedVenue: null
};

@State<UserStateModel>({
  name    : 'user',
  defaults: createCopy(DEFAULT_USER_DATA) as UserStateModel
})
@Injectable({ providedIn: 'root' })
export class UserProfileState {
  constructor(private userService: UserService,
              private store: Store,
  ) {
    this.store.select(AuthenticationState.isAuth).subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.store.dispatch([new GetUserProfileData(), new GetProfileAddresses(), new GetUserVenues()]);
      } else {
        this.store.dispatch(new ClearUserData());
      }
    });
  }

  @Selector()
  static userPhoto(state: UserStateModel) {
    return state.profile
        ? state.profile.employeePicture?.loResolution.url
        : null;
  }

  @Selector()
  static userFullName({ profile }: UserStateModel) {
    return profile ? `${profile.firstName} ${profile.lastName}` : '';
  }

  @Selector()
  static selectedVenueId(state: UserStateModel) {
    return state.selectedVenue
        ? state.selectedVenue.id
        : null;
  }

  @Selector()
  static selectedVenueAddress(state: UserStateModel) {
    return state.selectedVenue
        ? state.selectedVenue.address
        : null;
  }

  @Selector()
  static selectedVenueTenantId(state: UserStateModel) {
    return state.selectedVenue
        ? state.selectedVenue.tenantId
        : null;
  }

  @Action(GetUserProfileData)
  getUserProfileData({ patchState }: StateContext<UserStateModel>) {
    return this.userService.getUserInfo()
        .pipe(
            tap(profile => {
              patchState({ profile });
            }));
  }

  @Action(ClearUserData)
  clearUserData({ patchState }: StateContext<UserStateModel>) {
    patchState(createCopy(DEFAULT_USER_DATA) as UserStateModel);
  }

  @Action(GetProfileAddresses)
  getProfileAddresses({ patchState }: StateContext<UserStateModel>) {
    return this.userService.getProfileAddresses()
        .pipe(
            tap((addresses) => {
              patchState({ addresses });
            })
        );
  }

  @Action(GetUserVenues)
  getUserVenues({ patchState, getState }: StateContext<UserStateModel>) {
    return this.userService.getUserVenues()
        .pipe(
            tap((venues) => {
              patchState({
                venues
              });

              if (!getState().selectedVenue && venues?.length) {
                this.store.dispatch(new SelectUserVenue(venues[0]));
              }
            })
        );
  }

  @Action(SelectUserVenue)
  selectUserVenue(
      { patchState }: StateContext<UserStateModel>,
      { selectedVenue }: SelectUserVenue
  ) {
    return this.store.dispatch(new SwitchContextByTenantId(selectedVenue.tenantId)).pipe(
        tap(() => {
          patchState({
            selectedVenue
          });
        })
    );
  }
}
