import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { CurrencySymbol, CurrencyType } from '../enums/currency.enum';

@Pipe({name: 'customCurrency'})
export class CustomCurrencyPipe implements PipeTransform {
  public transform(currency: string, currencyCode: CurrencyType | CurrencySymbol | string = CurrencyType.USD): string {
    if (currency || currency.toString() === '0') {
      currency = formatNumber((parseInt(currency) / 100), 'en').toString();
      currency = `${CurrencySymbol[currencyCode]} ${currency}`;

      const cents = currency.split('.')[1];

      if (!cents) {
        currency += '.00';
      } else if (cents.toString().length === 1) {
        currency += '0';
      }

      return currency;
    }
  }
}
