import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { Store } from '@ngxs/store';

import { Navigate } from '@ngxs/router-plugin';

import { PAGES } from '../constants/pages';
import { AuthenticationState } from 'src/app/ngxs/authentication.state';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isAuth = this.store.selectSnapshot(AuthenticationState.isAuth);

    if (!isAuth) {
      this.redirectToAuthenticationPage();
    }

    return isAuth;
  }

  private redirectToAuthenticationPage(): void {
    this.store.dispatch(new Navigate([PAGES.SIGN_IN]));
  }
}
