import { Pipe, PipeTransform } from '@angular/core';

import { replaceCardNumber } from '../utils/payment-card';

@Pipe({name: 'card'})
class CardPipe implements PipeTransform {
  public transform(cardNumber: string, extended: boolean): string {
    if (cardNumber) {
      const SHOWED_NUMBER_CARD_DIGITS = 4;
      const cardNumberLength = cardNumber.toString().length;
      const showedCardDigits = cardNumberLength > SHOWED_NUMBER_CARD_DIGITS ?
        cardNumber.toString().substring(cardNumberLength - SHOWED_NUMBER_CARD_DIGITS, cardNumberLength) :
        cardNumber.toString();

      return extended ? `xxxx-xxxx-xxxx-${showedCardDigits}` : `****${showedCardDigits}`;
    }
  }
}

@Pipe({name: 'dashCardNumber'})
class DashCardPipe implements PipeTransform {
  public transform(cardNumber: string): string {
    const cardNumberValue = replaceCardNumber(cardNumber.toString());
    const inputValue = cardNumberValue.substr(0, 16);
    const cardValueWithoutHyphen = inputValue.split('-').join('');

    const formattedValue = (cardValueWithoutHyphen.length > 0)
      ? cardValueWithoutHyphen.match(new RegExp('.{1,4}', 'g')).join('-')
      : cardValueWithoutHyphen;

    return formattedValue;
  }
}

const sliceCardNumber = (cardNumber: string): string => cardNumber.toString().slice(-4);

@Pipe({name: 'shortCardNumber'})
class ShortCardNumberPipe implements PipeTransform {
  public transform(data: string): string {
    if (data) return sliceCardNumber(data);
  }
}

@Pipe({name: 'shortCardNumberWithAsterisk'})
class ShortCardNumberWithAsteriskPipe implements PipeTransform {
  public transform(data: string): string {
    if (data) return '*' + sliceCardNumber(data);
  }
}

@Pipe({name: 'cardDate'})
class CardDatePipe implements PipeTransform {
  public transform(dateString: string): string {
    const date  = new Date(dateString);
    const month = (date.getMonth() + 1).toString();
    const year  = date.getFullYear().toString().slice(-2);

    return ((month.length) < 2 ? '0' : '') + month + '/' + year;
  }
}

export {
  CardPipe,
  DashCardPipe,
  ShortCardNumberPipe,
  ShortCardNumberWithAsteriskPipe,
  CardDatePipe
};
