import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../shared/modules/material.module';
import { ImageOrDefaultIconModule } from '../../shared/modules/ui/image-or-default-icon/image-or-default-icon.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { ItemQuantityModule } from 'src/app/shared/modules/controls/item-quantity/item-quantity.module';

import { CartComponent } from './cart/cart.component';
import { CartItemComponent } from './cart-item/cart-item.component';
import { CartTotalComponent } from './cart-total/cart-total.component';

const components = [CartComponent, CartItemComponent, CartTotalComponent];

@NgModule({
            declarations: [...components],
            imports: [
              SharedModule,
              TranslateModule,
              MaterialModule,
              ImageOrDefaultIconModule,
              PipesModule,
              ItemQuantityModule
            ],
            exports: [...components]
          })
export class CartModule { }
