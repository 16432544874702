import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { HEADERS } from '../constants/common';
import { EXCLUDED_ROUTES_FOR_ACCESS_TOKEN, EXCLUDED_ROUTES_FOR_VENUE_TOKEN } from '../constants/endpoint';
import { addVersionsForMSPath, replaceAliasToRealApiPath, } from '../utils/endpoint';
import { AuthenticationState } from 'src/app/ngxs/authentication.state';
import { Logout, SaveAuthenticationData, SaveVenueAuthenticationData, } from 'src/app/ngxs/authentication.actions';
import { AuthenticationService } from '../services/authentication.service';
import { ERROR_STATUSES } from '../constants/errors';
import { AuthenticationData } from '../models/authentication-data.model';
import { UserProfileState } from 'src/app/ngxs/user.state';

const tempExcludedRoutes           = EXCLUDED_ROUTES_FOR_ACCESS_TOKEN.map((url) =>
    addVersionsForMSPath(url)
);
const excludedRoutesForAccessToken = [
  ...EXCLUDED_ROUTES_FOR_ACCESS_TOKEN,
  ...tempExcludedRoutes,
  ...tempExcludedRoutes.map((url) => replaceAliasToRealApiPath(url)),
];
const excludedRoutesForVenueToken  = EXCLUDED_ROUTES_FOR_VENUE_TOKEN.map((url) => addVersionsForMSPath(url));

@Injectable({ providedIn: 'root' })
export class AccessTokenInterceptor implements HttpInterceptor {
  private store: Store;
  private authService: AuthenticationService;
  private pendingSubject           = new BehaviorSubject<boolean>(false);
  private refreshTokenSubject      = new BehaviorSubject<string>(null);
  private venueTokenPendingSubject = new BehaviorSubject<boolean>(false);
  private refreshVenueTokenSubject = new BehaviorSubject<string>(null);

  constructor(private readonly injector: Injector) {
  }

  intercept(
      request: HttpRequest<any>,
      next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.store       = this.injector.get(Store);
    this.authService = this.injector.get(AuthenticationService);

    if (!excludedRoutesForAccessToken.some((route) => request.url.indexOf(route) > -1)) {
      const token      = this.store.selectSnapshot(AuthenticationState.accessToken);
      const venueToken = this.store.selectSnapshot(AuthenticationState.venueToken);
      let currentToken = excludedRoutesForVenueToken.some((route) => request.url.indexOf(route) > -1)
          ? token
          : venueToken;

      if (request.url.indexOf('/profile/me') > -1 && request.url.indexOf('/profile/me/address') === -1) {
        currentToken = venueToken || token;
      }

      if (currentToken) {
        return this.handleRequest(request, next, currentToken).pipe(
            catchError((error) => {
              if (
                  error instanceof HttpErrorResponse &&
                  error.status === ERROR_STATUSES.UNAUTHORIZED
              ) {
                if (excludedRoutesForVenueToken.some((route) => request.url.indexOf(route) > -1)) {
                  return this.handle401Error(request, next);
                }

                return this.handle401VenueTokenError(request, next);
              } else {
                throw error;
              }
            })
        );
      }
    }

    return next.handle(request);
  }

  private refreshToken(): Observable<AuthenticationData> {
    this.pendingSubject.next(true);
    this.refreshTokenSubject.next(null);

    return this.authService.refreshToken(
        this.store.selectSnapshot(AuthenticationState.refreshToken)
    );
  }

  private refreshVenueToken(): Observable<AuthenticationData> {
    this.venueTokenPendingSubject.next(true);
    this.refreshVenueTokenSubject.next(null);

    if (!this.pendingSubject.getValue()) {
      return this.getVenueAuthData();
    } else {
      return this.refreshTokenSubject.pipe(
          filter((token) => token !== null),
          take(1),
          switchMap(() => this.getVenueAuthData())
      );
    }
  }

  private getVenueAuthData(): Observable<AuthenticationData> {
    return this.authService.switchContext(
        this.store.selectSnapshot(UserProfileState.selectedVenueTenantId)
    );
  }

  private handle401VenueTokenError(
      request: HttpRequest<any>,
      next: HttpHandler
  ): Observable<any> {
    if (!this.venueTokenPendingSubject.getValue()) {
      if (!this.pendingSubject.getValue()) {
        return this.handleVenueTokenRefresh(request, next);
      } else {
        return this.refreshTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap(() => this.handleVenueTokenRefresh(request, next))
        );
      }
    } else {
      return this.refreshVenueTokenSubject.pipe(
          filter((token) => token !== null),
          take(1),
          switchMap((token) => this.handleRequest(request, next, token))
      );
    }
  }

  private handleVenueTokenRefresh(
      request: HttpRequest<any>,
      next: HttpHandler
  ): Observable<any> {
    return this.refreshVenueToken().pipe(
        switchMap((response) => {
          const { accessToken } = response;

          if (accessToken) {
            this.store.dispatch(new SaveVenueAuthenticationData(response));
            this.refreshVenueTokenSubject.next(accessToken);
            return this.handleRequest(request, next, accessToken);
          } else {
            return this.handleRefreshError();
          }
        }),
        catchError(() => this.handleRefreshError()),
        finalize(() => this.venueTokenPendingSubject.next(false))
    );
  }

  private saveAccessToken(data: AuthenticationData): void {
    this.store.dispatch(new SaveAuthenticationData(data));
    this.refreshTokenSubject.next(data.accessToken);
  }

  private handle401Error(
      request: HttpRequest<any>,
      next: HttpHandler
  ): Observable<any> {
    if (!this.pendingSubject.getValue()) {
      return this.refreshAccessToken(request, next);
    } else {
      return this.refreshTokenSubject.pipe(
          filter((token) => token !== null),
          take(1),
          switchMap((token) => this.handleRequest(request, next, token))
      );
    }
  }

  private refreshAccessToken(
      request: HttpRequest<any>,
      next: HttpHandler,
  ): Observable<any> {
    return this.refreshToken().pipe(
        switchMap((response) => {
          const { accessToken } = response;

          if (accessToken) {
            this.saveAccessToken(response);
            return this.handleRequest(request, next, accessToken);
          } else {
            return this.handleRefreshError();
          }
        }),
        catchError(() => this.handleRefreshError()),
        finalize(() => this.pendingSubject.next(false))
    );
  }

  private handleRequest(
      request: HttpRequest<any>,
      next: HttpHandler,
      token: string
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: request.headers.set(HEADERS.ACCESS_TOKEN, token),
    });

    return next.handle(request);
  }

  private handleRefreshError(): Observable<never> {
    this.store.dispatch(new Logout());

    return throwError(null);
  }
}
