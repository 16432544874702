import { NgModule } from '@angular/core';

import { ClickAndHoldDirective } from './click-and-hold.directive';
import { IntegerNumberOnlyDirective } from './integers-only.directive';

@NgModule({
  declarations: [ClickAndHoldDirective, IntegerNumberOnlyDirective],
  exports: [ClickAndHoldDirective, IntegerNumberOnlyDirective],
})
export class DirectivesModule {}
