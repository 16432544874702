import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  CardPipe,
  DashCardPipe,
  ShortCardNumberPipe,
  CardDatePipe,
  ShortCardNumberWithAsteriskPipe
} from './card.pipe';

import {
  DevicePricePipe,
  TotalEveryDayPayPipe,
  EveryDayPayPipe,
  TotalFirstPayPipe,
  FirstPayPipe,
  PricePipe
} from './price.pipe';

import { PhotoPipe } from './device.pipe';
import { CustomCurrencyPipe } from './custom-currency.pipe';
import { CreatedAtDatePipe } from './date.pipe';
import { ShowFullAddressPipe } from './show-full-address.pipe';
import { AddressPipe } from './address.pipe';

const pipes = [
  CardPipe,
  DashCardPipe,
  ShortCardNumberPipe,
  ShortCardNumberWithAsteriskPipe,
  CardDatePipe,
  PricePipe,
  DevicePricePipe,
  FirstPayPipe,
  EveryDayPayPipe,
  PhotoPipe,
  TotalFirstPayPipe,
  TotalEveryDayPayPipe,
  CustomCurrencyPipe,
  CreatedAtDatePipe,
  ShowFullAddressPipe,
  AddressPipe
];

@NgModule({
            imports     : [CommonModule],
            declarations: [...pipes],
            providers   : [...pipes],
            exports     : [...pipes]
          })
export class PipesModule {
}
