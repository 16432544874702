import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SetSpinnerVisibility } from './ngxs/app.actions';
import { ClearCartNotification } from './ngxs/cart.actions';

@Component({
  selector: 'rs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  @Select((state) => state.app.spinnerVisibility)
  spinnerVisibility$: Observable<boolean>;

  constructor(
    private translateService: TranslateService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.setDefaultBrowserLanguage();
    this.store.dispatch([new SetSpinnerVisibility(false), new ClearCartNotification()]);
  }

  private setDefaultBrowserLanguage(): void {
    this.translateService.use(this.translateService.getBrowserLang());
  }
}
