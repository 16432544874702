import { ERRORS } from '../constants/errors';
import { Error } from '../models/error.model';

export const getErrorTitleByCode = (errorCode: number) => {
  const error: Error = ERRORS.find(
    (currentError: Error) => currentError.code === errorCode
  );

  return error?.errorTitle;
};

export const getErrorContentByCode = (error: {
  code: number;
  message: string;
}) => {
  const errorDescription: Error = ERRORS.find(
    (currentError: Error) => currentError.code === error.code
  );

  return errorDescription?.errorContent ?? error.message;
};
