<li class="rs-address w-100 d-flex align-items-center justify-content-between">
  <div class="d-flex align-items-center">
    <div class="rs-address__status" [ngClass]="{ 'rs-active': isSelected }">
      <button
        mat-icon-button
        aria-hidden="false"
        attr.aria-label="{{ 'aria-labels.address-check-status' | translate }}"
        (click)="selectAddress()"
      >
        <mat-icon class="rs-active" *ngIf="isSelected">check_circle</mat-icon>
        <mat-icon class="rs-inactive" *ngIf="!isSelected">radio_button_unchecked</mat-icon>
      </button>
    </div>
    <div class="rs-address__content">
      <p>{{ address | fullAddress }}</p>
    </div>
  </div>
  <div class="rs-address__actions d-flex" *ngIf="showActionButtons">
    <button
      mat-icon-button
      aria-hidden="false"
      attr.aria-label="{{ 'aria-labels.edit-address' | translate }}"
      (click)="editAddress()"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      mat-icon-button
      aria-hidden="false"
      attr.aria-label="{{ 'aria-labels.delete-address' | translate }}"
      (click)="deleteAddress()"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</li>