import { NgModule } from '@angular/core';
import { LayoutModule } from 'src/app/modules/layout/layout.module';
import { SignInModule } from 'src/app/modules/sign-in/sign-in.module';

@NgModule({
  exports: [
    LayoutModule,
    SignInModule
  ],
})
export class MainModulesModule {}
