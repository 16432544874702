export const PAGES = {
  SIGN_IN: '/sign-in',
  MARKETPLACE: '/marketplace',
  CALCULATOR: '/marketplace/calculator',
  CALCULATOR_RESULT: '/marketplace/calculator-result',
  MY_DEVICES: '/my-devices',
  ORDERS_HYSTORY: '/orders-history',
  CHECKOUT: '/marketplace/checkout'
};

export const PAGE_TABS = [
  {
    title: 'titles.marketplace',
    link: PAGES.MARKETPLACE,
  },
  {
    title: 'titles.my-devices',
    link: PAGES.MY_DEVICES
  },
  {
    title: 'titles.orders-history',
    link: PAGES.ORDERS_HYSTORY,
  }
];
