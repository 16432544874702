<div>
  <img
    loading="lazy"
    *ngIf="photo; else defaultImg"
    [src]="photo"
    alt=""
  />
  <ng-template #defaultImg>
    <mat-icon
      *ngIf="iconName"
      [ngStyle.color]="color">{{iconName}}</mat-icon>
    <img
      loading="lazy"
      *ngIf="iconSRC"
      [src]="iconSRC"
      [style.fill]="color"
      alt=""
    />
  </ng-template>
</div>
