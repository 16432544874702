import { Component } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SetSideComponent, SetSideVisibility } from 'src/app/ngxs/app.actions';
import { Logout } from 'src/app/ngxs/authentication.actions';
import { UserProfileState } from 'src/app/ngxs/user.state';
import { SidenavComponent } from 'src/app/shared/enums/sidenav.enum';
import { Venue } from 'src/app/shared/models/venue.model';
import { BACKOFFICE_DATA } from '../../constants/backoffice-data';

@Component({
  selector: 'rs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Select(UserProfileState.userPhoto)
  userPhoto$: Observable<string>;

  @Select((state) => state.user.venues)
  venues$: Observable<Venue[]>;

  @Select((state) => state.user.selectedVenue)
  selectedVenue$: Observable<Venue>;

  public readonly backOfficeMenuData = BACKOFFICE_DATA;

  constructor(private store: Store) {}

  openMenu(): void {}

  openVenues(): void {
    this.store.dispatch([
      new SetSideVisibility(true),
      new SetSideComponent(SidenavComponent.UserVenues),
    ]);
  }

  logout(): void {
    this.store.dispatch(new Logout());
  }
}
