import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from '../constants/endpoint';
import { UserProfileAddress } from '../models/address.model';
import { User } from '../models/user.model';
import { ApiService } from './api.service';
import { Venue } from 'src/app/shared/models/venue.model';

@Injectable({providedIn: 'root'})
export class UserService {
  constructor(private apiService: ApiService) {
  }

  public getUserInfo(): Observable<User> {
    return this.apiService.get(ENDPOINTS.memberArea.profile.getUserProfile);
  }

  public getProfileAddresses(): Observable<UserProfileAddress[]> {
    return this.apiService.get(ENDPOINTS.memberArea.profile.userAddresses);
  }

  public createNewProfileAddress(address: UserProfileAddress): Observable<{id: string}> {
    return this.apiService.post(ENDPOINTS.memberArea.profile.userAddresses, address);
  }

  public updateProfileAddressById(id: string, address: UserProfileAddress): Observable<void> {
    return this.apiService.put(`${ENDPOINTS.memberArea.profile.userAddresses}/${id}`, address);
  }

  public deleteProfileAddressById(id: string): Observable<any> {
    return this.apiService.delete(`${ENDPOINTS.memberArea.profile.userAddresses}/${id}`);
  }

  public getUserVenues(): Observable<Venue[]> {
    return this.apiService.get(ENDPOINTS.memberArea.profile.venues);
  }
}
