// TODO: clarify available options for PaymentFrequency, TransactionType enums after integration with BE
enum PaymentFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  ANNUALLY = 'annually'
};

enum PaymentTerm {
  daily = 'day',
  weekly = 'week',
  monthly = 'month',
  annually = 'year'
}

enum TransactionType {
  DISPUTE = 'dispute',
  REFUND = 'refund',
  SETTLEMENT = 'settlement',
}

export {
  PaymentFrequency,
  PaymentTerm,
  TransactionType
};
