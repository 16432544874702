import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SetSideVisibility } from 'src/app/ngxs/app.actions';
import { AuthenticationState } from 'src/app/ngxs/authentication.state';
import { CartState } from 'src/app/ngxs/cart.state';
import { MAIN_CONTENT_CLASS } from 'src/app/shared/constants/common';
import { SidenavComponent } from 'src/app/shared/enums/sidenav.enum';
import { CartNotification } from 'src/app/shared/models/cart-notification.model';

@UntilDestroy()
@Component({
  selector: 'rs-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  @Select((state) => state.app.sideVisibility)
  sideVisibility$: Observable<boolean>;
  @Select((state) => state.app.sideComponent)
  sideComponent$: Observable<boolean>;
  @Select(CartState.notification)
  cartNotification$: Observable<CartNotification>;
  @Select(AuthenticationState.venueToken)
  venueToken$: Observable<boolean>;
  @Select(AuthenticationState.venueTokenIsLoading)
  venueTokenIsLoading$: Observable<boolean>;

  @ViewChild('mainContent') mainContentRef: ElementRef<HTMLElement>;

  public readonly SidenavComponent = SidenavComponent;
  public readonly MAIN_CONTENT_CLASS = MAIN_CONTENT_CLASS;

  constructor(private store: Store, private actions$: Actions) {}

  ngOnInit(): void {
    this.actions$
      .pipe(ofActionDispatched(Navigate), untilDestroyed(this))
      .subscribe(() => this.mainContentRef?.nativeElement?.scrollTo({
          top: 0,
          behavior: 'smooth'
        }));
  }

  close(): void {
    this.store.dispatch(new SetSideVisibility(false));
  }
}
