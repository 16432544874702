<div class="rs-sign-in">
  <div class="rs-sign-in__logo">
    <img src="assets/images/rockspoon-title.svg" />
  </div>
  <div class="rs-sign-in__title">{{ "authentication.login" | translate }}</div>
  <div
    class="rs-sign-in__form"
    [formGroup]="signInForm"
    (keydown.enter)="signIn()"
  >
    <mat-form-field>
      <mat-label>{{ "placeholders.email" | translate }}</mat-label>
      <input
        type="email"
        formControlName="username"
        matInput
        placeholder="{{ 'placeholders.email' | translate }}"
        (keyup)="clearEmailOrPasswordIsIncorrectError()"
        (paste)="clearEmailOrPasswordIsIncorrectError()"
        (keydown.enter)="signIn()"
      />
      <mat-error class="rs-app__validation-error"
        *ngIf="signInError$ | async; let signInError">
          {{ signInError | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "placeholders.password" | translate }}</mat-label>
      <input
        formControlName="password"
        [type]="showPassword ? 'text' : 'password'"
        placeholder="{{ 'placeholders.password' | translate }}"
        (keydown.enter)="signIn()"
        (keyup)="clearEmailOrPasswordIsIncorrectError()"
        (paste)="clearEmailOrPasswordIsIncorrectError()"
        matInput
      />
      <mat-icon
        class="rs-go-suffix-button"
        *ngIf="showPassword && !isInvalidControl(passwordControl)"
        matSuffix
        (click)="showPassword = !showPassword"
        >visibility</mat-icon
      >
      <mat-icon
        class="rs-go-suffix-button"
        *ngIf="!showPassword && !isInvalidControl(passwordControl)"
        matSuffix
        (click)="showPassword = !showPassword"
        >visibility_off</mat-icon
      >
      <mat-icon
        class="rs-go-login__warning-icon"
        *ngIf="isInvalidControl(passwordControl)"
        matSuffix
        >warning</mat-icon
      >
      <mat-error
        class="rs-app__validation-error"
        *ngIf="
          isInvalidControl(passwordControl) &&
          passwordControl.hasError('minlength')
        "
      >
        {{ "errors.validation.password" | translate }}
      </mat-error>
    </mat-form-field>
    <button
      [disabled]="signInForm.invalid"
      mat-raised-button
      class="app__button"
      color="primary"
      (click)="signIn()"
    >
      {{ "authentication.login" | translate }}
    </button>
  </div>
</div>
<rs-footer></rs-footer>
