import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'rs-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  public dialogTitle: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: { dialogTitle: string }) {
    this.dialogTitle = data.dialogTitle;
  }
}
