import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PayService } from 'src/app/shared/services/api.service';
import { Card, NewCardToAdd, CardToEdit } from 'src/app/shared/models/card.model';
import { ENDPOINTS } from '../constants/endpoint';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(private readonly payService: PayService) {
  }

  public getCards(): Observable<Card[]> {
    return this.payService.get(ENDPOINTS.wallet.cardToken);
  }

  public createCard(newCardToAdd: NewCardToAdd): Observable<any> {
    return this.payService.post(ENDPOINTS.wallet.cardToken, newCardToAdd);
  }

  public editCard(cardToEdit: CardToEdit, cardId: string): Observable<void> {
    return this.payService.put(`${ENDPOINTS.wallet.cardToken}/${cardId}`, cardToEdit);
  }

  public deleteCard(cardId: string): Observable<void> {
    return this.payService.delete(`${ENDPOINTS.wallet.cardToken}/${cardId}`);
  }

}
