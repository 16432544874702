import {
  API_ALIASES,
  API_ALIAS_REPLACEMENTS,
  AVAILABLE_MS_VERSIONS,
  MS_DEPENDS_ON_VERSIONS, MOCK_URL_PART
} from '../constants/endpoint';

export const addVersionsForMSPath = (url: string): string => {
  AVAILABLE_MS_VERSIONS.forEach((msVersion) => {
    const urlDependsOnVersion = url.replace(API_ALIASES.apiUrlAlias, '').replace(API_ALIASES.paymentApiUrlAlias, '');
    const chunks = urlDependsOnVersion.split('/');
    const msName = chunks.shift();

    const msForCurrentVersion = MS_DEPENDS_ON_VERSIONS[msVersion];

    if (msForCurrentVersion && msForCurrentVersion.length && (msForCurrentVersion.indexOf(msName) > -1)) {
      url = url.replace(msName, msName + msVersion);
    }
  });

  return url;
};

export const replaceAliasToRealApiPath = (url: string): string => {
  const aliasesKeys = Object.keys(API_ALIASES);

  aliasesKeys.forEach((key) => {
    const alias = API_ALIASES[key];
    if (url.includes(alias)) {
      url = url.replace(alias, API_ALIAS_REPLACEMENTS[alias]);
    }
  });

  return url;
};

export const getEndpointForCartId = (endpoint: string, cartId: string): string => {
  return endpoint.split(MOCK_URL_PART.cart).join(cartId);
};

export const getEndpointForOrderId = (endpoint: string, orderId: string): string => {
  return endpoint.split(MOCK_URL_PART.order).join(orderId);
};

export const getEndpointForItemId = (endpoint: string, itemId: string): string => {
  return endpoint.split(MOCK_URL_PART.item).join(itemId);
};

export const getEndpointForContextSwitch = (endpoint: string, tenantId: string): string => {
  return endpoint.split(MOCK_URL_PART.tenantId).join(tenantId);
};

export const  getOrderItemQueryParameter = (isFirst: boolean, value: string): string => {
  const parameterName = 'orderItemId=';
  const firstJoinParameterMark = '?';
  const restJoinParameterMark = '&';

  return isFirst ? `${firstJoinParameterMark}${parameterName}${value}` : `${restJoinParameterMark}${parameterName}${value}`;
};

export const generateUrlForMultipleOrderItemIds = (orderItemIds: string[]): string => {
  return orderItemIds.reduce((acc, id, index) =>
                               (acc + ((index === 0) ?
                                       getOrderItemQueryParameter(true, id) :
                                       getOrderItemQueryParameter(false, id))), ''
  );
};

export const isCurrentUrl = (url: string, endpoint: string) => {
  return (url.indexOf(endpoint) > -1);
};
