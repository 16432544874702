import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { NotificationService } from '../services/notification.service';
import { SetSpinnerVisibility } from '../../ngxs/app.actions';
import { ERROR_STATUSES } from '../constants/errors';
import { EXCLUDED_ROUTES_FOR_SHOWING_ERRORS } from '../constants/endpoint';
import { addVersionsForMSPath } from '../utils/endpoint';

const excludedRoutesForShowingErrors = EXCLUDED_ROUTES_FOR_SHOWING_ERRORS.map(
  (url) => addVersionsForMSPath(url)
);
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private store: Store;
  private notificationService: NotificationService;

  constructor(private injector: Injector) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.store = this.injector.get(Store);
    this.notificationService = this.injector.get(NotificationService);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.store.dispatch(new SetSpinnerVisibility(false));

        if (
          error.status === ERROR_STATUSES.UNKNOWN ||
          error.status === ERROR_STATUSES.UNAUTHORIZED ||
          excludedRoutesForShowingErrors.findIndex(
            (route) => error.url.indexOf(route) > -1
          ) > -1
        ) {
          throw error;
        }

        this.notificationService.showErrorMessage(
          error.statusText,
          error.error.message
        );
        throw error;
      })
    );
  }
}
