import { MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

export const COMMON_CONSTANTS = {
  DEFAULT_LANGUAGE: 'en',
  API_URL         : environment.apiUrl,
};

export const HEADERS = {
  KEY         : 'key',
  ACCESS_TOKEN: 'access_token',
};

export const ZIP_CODE_MASK = '00000';

export const INPUT_DEBOUNCE_TIME = 300;
export const TIMEOUT_FOR_NOTIFICATION_ERASE = 3000;
export const MAIN_CONTENT_CLASS = 'rs-layout__main-content';
const DIALOG_CLASS = 'rs-dialog';

export const DIALOG_PARAMETERS: Record<string, MatDialogConfig> = {
  productDialog: {
    maxWidth: '960px',
    width: '100%',
    maxHeight: 'calc(100vh - 100px)',
    panelClass: DIALOG_CLASS,
  },
  confirmation: {
    panelClass: DIALOG_CLASS,
    position: {
      top: '150px',
    },
  },
  cardDialog: {
    maxWidth: '760px',
    width: '100%',
    panelClass: DIALOG_CLASS,
  },
  deleteCardDialog: {
    maxWidth: '560px',
    width: '100%',
    panelClass: DIALOG_CLASS,
  },
  chooseDefaultCard: {
    maxWidth: '760px',
    width: '100%',
    panelClass: DIALOG_CLASS,
    disableClose: true,
  },
};
