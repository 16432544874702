import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SidenavComponent } from '../shared/enums/sidenav.enum';
import {
  SetSideComponent,
  SetSideVisibility,
  SetSpinnerVisibility,
} from './app.actions';

interface AppStateModel {
  sideVisibility: boolean;
  sideComponent: SidenavComponent;
  spinnerVisibility: boolean;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    sideVisibility: false,
    sideComponent: null,
    spinnerVisibility: false,
  },
})
@Injectable()
export class AppState {
  constructor() {}

  @Selector()
  static cartIsOpen(state: AppStateModel): boolean {
    return state.sideVisibility && state.sideComponent === SidenavComponent.Cart;
  }

  @Action(SetSideVisibility)
  setSideVisibility(
    { patchState }: StateContext<AppStateModel>,
    { sideVisibility }: SetSideVisibility
  ) {
    patchState({
      sideVisibility,
    });
  }

  @Action(SetSideComponent)
  setSideComponent(
    { patchState }: StateContext<AppStateModel>,
    { sideComponent }: SetSideComponent
  ) {
    patchState({
      sideComponent,
    });
  }

  @Action(SetSpinnerVisibility)
  setSpinnerVisibility(
    { patchState }: StateContext<AppStateModel>,
    { spinnerVisibility }: SetSpinnerVisibility
  ) {
    patchState({
      spinnerVisibility,
    });
  }
}
