<div class="rs-calculator-sidenav">
  <div class="d-flex align-items-center rs-calculator-sidenav__header">
    <div class="rs-side-nav-title flex-grow-1">
      <h2>{{ "titles.calculator-sidenav-title" | translate }}</h2>
    </div>
    <button mat-icon-button>
      <mat-icon (click)="close()">close</mat-icon>
    </button>
  </div>

  <div class="rs-calculator-sidenav__form">
    <form [formGroup]="calculatorForm">
      <ng-container *ngFor="let controlItem of CALCULATOR_CONTROLS">
        <div class="form-group">
          <p>{{ controlItem.label | translate }}</p>

          <mat-form-field
            *ngIf="!controlItem.isToggle"
            appearance="outline"
            class="w-100"
          >
            <input
              matInput
              [formControlName]="controlItem.name"
              placeholder="{{ 'placeholders.number' | translate }}"
              type="number"
              rsIntegerNumberOnly
            />
          </mat-form-field>

          <mat-radio-group
            *ngIf="controlItem.isToggle"
            [formControlName]="controlItem.name"
          >
            <mat-radio-button
              *ngFor="let item of TOGGLE_ITEMS"
              [value]="item.value"
              >{{ item.title | translate }}</mat-radio-button
            >
          </mat-radio-group>
        </div>
      </ng-container>

      <div class="rs-calculator-sidenav__actions">
        <button mat-flat-button class="mr-3" color="basic" (click)="clear()">
          {{ "buttons.clear" | translate }}
        </button>

        <button
          mat-flat-button
          color="primary"
          [disabled]="calculatorForm.invalid"
          (click)="find()"
        >
          {{ "buttons.find" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
