import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

export class BaseApiService {

  constructor(
    protected readonly apiUrl: string,
    protected readonly http: HttpClient) {}

  public get(endpoint: string, queryParams?: any): Observable<any> {
    const httpParams = new HttpParams({ fromObject: queryParams });
    return this.http.get<any>(`${this.apiUrl}${endpoint}`, { params: httpParams });
  }

  public post(endpoint: string, body?: any, queryParams?: any): Observable<any> {
    const httpParams = new HttpParams({ fromObject: queryParams });
    return this.http.post(`${this.apiUrl}${endpoint}`, body, { params: httpParams });
  }

  public put(endpoint: string, body: any): Observable<any> {
    return this.http.put(`${this.apiUrl}${endpoint}`, body);
  }

  public patch(endpoint: string, body: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}${endpoint}`, body);
  }

  public delete(endpoint: string, queryParams?: any): Observable<any> {
    const httpParams = new HttpParams({ fromObject: queryParams });

    return this.http.delete<any>(`${this.apiUrl}${endpoint}`, { params: httpParams });
  }
}
