import { HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import {
  MOCK_CART_ID,
  // MOCK_ITEM_DEVICES_ARRAY,
  MOCK_ORDERS,
  MOCK_ORDER_ID,
  MOCK_ORDER_PAYMENT,
  // MOCK_RECOMMENDED_DEVICES_ARRAY,
} from '../constants/mock';
import { ENDPOINTS } from '../constants/endpoint';
import { getEndpointForCartId, getEndpointForOrderId, isCurrentUrl } from '../utils/endpoint';


export const simulateApiAnswer = (request: HttpRequest<any>): Observable<HttpResponse<any>> | null => {
  const {params, url, method} = request;
  let body;

  const status = 200;
  if (method === 'POST') {
    switch (true) {
      default:
        break;
    }
  }

  if (method === 'GET') {
    switch (true) {
      // case isCurrentUrl(url, ENDPOINTS.catalog.devicesList):
      //   body = [...MOCK_ITEM_DEVICES_ARRAY];
      //   break;
      case isCurrentUrl(url, ENDPOINTS.order.getAllOrders): {
        body = {
          total: MOCK_ORDERS.length,
          next: null,
          previous: null,
          results: [...MOCK_ORDERS]
        };
        break;
      }
      case isCurrentUrl(url, '/payment/consumer?orderId='): {
        body = [...MOCK_ORDER_PAYMENT];
        break;
      }
      default:
        break;
    }
  }

  if (method === 'POST') {
    switch (true) {
      // case isCurrentUrl(url, ENDPOINTS.catalog.recommendations):
      //   body = [...MOCK_RECOMMENDED_DEVICES_ARRAY];
      //   break;
      case isCurrentUrl(url, getEndpointForCartId(ENDPOINTS.order.commitCart, MOCK_CART_ID)):
        body = {orderId: MOCK_ORDER_ID};
        break;
      case isCurrentUrl(url, getEndpointForOrderId(ENDPOINTS.order.placeAnOrder, MOCK_ORDER_ID)):
        body = {};
        break;
      // case isCurrentUrl(url, ENDPOINTS.order.createCart):
      //   body = {id: MOCK_CART_ID};
      //   break;
      default:
        break;
    }
  }

  if (body && status) {
    return of(new HttpResponse({status: 200, body}));
  } else {
    return null;
  }
};
