import { Authentication } from '../modules/sign-in/models/authentication.model';
import { AuthenticationData } from '../shared/models/authentication-data.model';

export class SignIn {
  static readonly type = '[User] Sign In';

  constructor(public authentication: Authentication) {}
}

export class SignInError {
  static readonly type = '[Auth Error] Authentication Error';

  constructor(public signInError: string = '') {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class SaveAuthenticationData {
  static readonly type = '[Auth] Save refreshed authentication data';

  constructor(public authentication: AuthenticationData) {}
}

export class SaveVenueAuthenticationData {
  static readonly type = '[Auth] Save refreshed venue authentication data';

  constructor(public authentication: AuthenticationData) {}
}
export class SaveRefreshToken {
  static readonly type = '[Auth] Save refresh Token';

  constructor(public token: string) {}
}

export class SwitchContextByTenantId {
  static readonly type = '[Auth] switch context by tenantId';

  constructor(public tenantId: string) {}
}
