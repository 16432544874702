import { SidenavComponent } from '../shared/enums/sidenav.enum';

export class SetSideVisibility {
  static readonly type = '[Side Visibility] Set';

  constructor(public sideVisibility: boolean) {}
}

export class SetSideComponent {
  static readonly type = '[Side Component] Set';

  constructor(public sideComponent: SidenavComponent) {}
}

export class SetSpinnerVisibility {
  static readonly type = '[Spinner Visibility] Set';

  constructor(public spinnerVisibility: boolean) {}
}
