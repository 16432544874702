import { Pipe, PipeTransform } from '@angular/core';

const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
};

@Pipe({name: 'createdAtDate'})
export class CreatedAtDatePipe implements PipeTransform {
  public transform(date: string): string {
    return new Date(date).toLocaleDateString(undefined, options);
  }
}
