import { Pipe, PipeTransform } from '@angular/core';

import { Image, ImageResolution } from '../models/image.model';
import { createCopy } from '../utils/create-object-copy';

@Pipe({name: 'photo'})
export class PhotoPipe implements PipeTransform {
  public transform(photos: Image[] | string): string {
    if (typeof photos === 'string') {
      return photos;
    }
    
    let image = '';
    photos.forEach((photo: Image) => {

      const {resolutions} = photo;

      const {high, low} = resolutions;
      const defaultResolution = resolutions.default;

      [high, defaultResolution, low].forEach((resolution: ImageResolution | '') => {
        if (resolution) {
          const nonEmptyResolution = createCopy(resolution) as ImageResolution;
          if (nonEmptyResolution.url && !image) {
            image = nonEmptyResolution.url;
          }
        }
      });
    });

    return image;
  }
}
