import { Component, OnInit } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { SetSideComponent, SetSideVisibility } from '../../../../ngxs/app.actions';
import { CartState } from 'src/app/ngxs/cart.state';
import { PAGE_TABS } from '../../../constants/pages';
import { CartItem } from 'src/app/modules/cart/cart.models';
import { SidenavComponent } from '../../../enums/sidenav.enum';

@Component({
  selector: 'rs-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Select(CartState.items)
  cartItems$: CartItem[];

  public readonly tabs = PAGE_TABS;
  public selectedTab$: Observable<number>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.selectedTab$ = this.store.select(state => state.router.state)
      .pipe(
        map(({url}) => this.tabs.findIndex(({link}) => url.includes(link)))
      );
  }

  openCart(): void {
    this.store.dispatch([
      new SetSideVisibility(true),
      new SetSideComponent(SidenavComponent.Cart),
    ]);
  }

  onTabClick(link: string): void {
    this.store.dispatch(new Navigate([link]));
  }
}
