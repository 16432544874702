import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import { uniqBy } from 'lodash';

import {
  ClearOrderData,
  GetNextOrders,
  GetOrders,
  GetOrdersById,
  GetOrdersPaymentInformation
} from './order-history.actions';
import { SetSpinnerVisibility } from 'src/app/ngxs/app.actions';
import { createCopy } from 'src/app/shared/utils/create-object-copy';
import { Order, OrderDevice, OrderResult } from '../shared/models/order.model';
import { CheckSummary } from 'src/app/shared/models/check-summary.model';
import { OrderPaymentCardInfo } from 'src/app/shared/models/card.model';
import { OrderService } from '../shared/services/order.service';
import { PaymentInstalment, Payment, PaymentDetails } from '../shared/models/payment.model';
import { MOCK_PAYMENT_INSTALMENT } from '../shared/constants/mock';
import { CurrencySymbol } from '../shared/enums/currency.enum';
import { definePaymentCardType } from '../shared/utils/payment-card';

interface OrderHistoryStateModel {
  orders: Order[];
  order: Order;
  currentPaginator: {
    total: number;
    next: string;
    previous: string;
  };
  cardInfo: OrderPaymentCardInfo;
  checkSummary: CheckSummary;
  tips: number;
  paymentDetails: PaymentDetails[],
  paymentInstalment: PaymentInstalment[];
}

const defaultCardInfo: OrderPaymentCardInfo = {
  cardBrand: null,
  cardHolderName: null,
  cvv: null,
  redactedCardNumber: null,
  tokenId: null,
  type: null
} as OrderPaymentCardInfo;

const DAFAULT_ORDER_DATA = {
  orders           : [],
  order            : null,
  currentPaginator : null,
  cardInfo         : createCopy(defaultCardInfo) as OrderPaymentCardInfo,
  checkSummary     : null,
  tips             : 0,
  paymentDetails   : [],
  paymentInstalment: []
};

@State<OrderHistoryStateModel>({
  name    : 'orderHistory',
  defaults: createCopy(DAFAULT_ORDER_DATA) as OrderHistoryStateModel
})
@Injectable()
export class OrderHistoryState {

  constructor(private readonly store: Store,
              private readonly orderService: OrderService) {}

  @Selector()
    static orders({ orders }: OrderHistoryStateModel): Order[] {
    return orders;
  }

  @Selector()
  static myDevices({ orders }: OrderHistoryStateModel): OrderDevice[] {
    return orders.reduce((accumulator, order) => {
      const createdAt = order.createdAt;
      const items = (order.checks[0]?.entries || []).map((item) => ({...item, createdAt }));
      accumulator.push(...items);

      return accumulator;
    }, []);
  }

  @Action(GetOrders)
  getOrders({ patchState }: StateContext<OrderHistoryStateModel>, {parameters}: GetOrders) {
    this.store.dispatch(new SetSpinnerVisibility(true));

    return this.orderService.getOrders(parameters)
               .pipe(
                  tap((orderResult: OrderResult) => {
                    if (orderResult) {
                      patchState({
                        orders: (orderResult.results || []),
                        currentPaginator: {
                          total: orderResult.total,
                          next: orderResult.next,
                          previous: orderResult.previous
                        }
                      });
                    }

                    this.store.dispatch(new SetSpinnerVisibility(false));
                  })
               );
  }

  @Action(GetNextOrders)
  getNextOrders({ patchState, getState }: StateContext<OrderHistoryStateModel>, {parameters}: GetOrders) {
    this.store.dispatch(new SetSpinnerVisibility(true));

    const nextParameters = createCopy(parameters);
    const nextOrderId = getState().currentPaginator?.next;

    if (nextOrderId) {
      nextParameters.next = nextOrderId;
    }

    const existingOrders = createCopy(getState().orders);

    return this.orderService.getOrders(nextParameters)
               .pipe(
                  tap((orderResult: OrderResult) => {
                    if (orderResult) {
                      patchState({
                        orders    : uniqBy([...existingOrders, ...(orderResult.results || [])], 'orderId'),
                        currentPaginator: {
                          total   : orderResult.total,
                          next    : orderResult.next,
                          previous: orderResult.previous
                        }
                      });
                    }

                    this.store.dispatch(new SetSpinnerVisibility(false));
                  })
               );
  }

  @Action(GetOrdersById)
  getOrdersById({ patchState }: StateContext<OrderHistoryStateModel>, { id }: GetOrdersById) {

    return this.orderService.getOrderById(id)
               .pipe(
                  tap((order: Order) => {
                    patchState({order});
                  }, (error) => {
                    patchState({ order: {orderId: ''} as Order });
                  })
               );
  }

  @Action(GetOrdersPaymentInformation)
  getOrdersPaymentInformation({patchState}: StateContext<OrderHistoryStateModel>, {id}: GetOrdersPaymentInformation) {
    this.store.dispatch(new SetSpinnerVisibility(true));

    return this.orderService.getPaymentInformationByOrderId(id)
               .pipe(
                  tap((paymentResponse: Payment[] = []) => {
                    const payment = paymentResponse[0] ?? {} as Payment;

                    const cardInfo = payment && payment.cardInfo ? payment.cardInfo : createCopy(defaultCardInfo) as OrderPaymentCardInfo;
                    cardInfo.cardBrand = cardInfo?.cardBrand ? cardInfo?.cardBrand : definePaymentCardType(cardInfo?.redactedCardNumber);

                    const tips = payment?.tip?.amount || 0;
                    // TODO: clarify the way of getting payment instalment data
                    // const paymentInstalment = createCopy(MOCK_PAYMENT_INSTALMENT);
                    const paymentInstalment = null;

                    if (payment.amount) {
                      const sum = (payment.tax?.amount || 0) + (payment.fee?.amount || 0) + payment.amount.amount;
                      const checkSummary = {
                        tax: { percentage: 0, netPrice: payment.tax?.amount || 0 },
                        discount: { percentage: 0, netPrice: 0 },
                        serviceCharges: { percentage: 0, netPrice: 0 },
                        deliveryFee: { percentage: 0, netPrice: payment.fee?.amount || 0 },
                        extraFee: [],
                        total: sum,
                        subtotal: payment.amount.amount,
                        currency: CurrencySymbol[payment.amount.symbol],
                        minimumOrderValue: 0,
                        disputes: payment.disputes,
                        refunds: payment.refunds
                      } as CheckSummary;

                      patchState({
                        cardInfo,
                        checkSummary,
                        tips,
                        paymentDetails: payment.details,
                        // todo: make sure it's necessary part
                        paymentInstalment: paymentInstalment ? paymentInstalment : null });
                    } else {
                      patchState({ cardInfo, tips, paymentInstalment });
                    }

                    this.store.dispatch(new SetSpinnerVisibility(false));
                  })
                  // tap((paymentInformation: OrderPaymentInformation[] = []) => {
                  //   const payment = paymentInformation[0] ? paymentInformation[0] : {} as OrderPaymentInformation;
                  //   const cardInfo = payment ? payment.cardInfo : createCopy(defaultCardInfo) as OrderPaymentCardInfo;
                  //   const tips = payment && payment.tip ? payment.tip.amount || 0 : 0;

                  //   if (payment.amount) {
                  //     const sum = (payment.tax?.amount || 0) + (payment.fee?.amount || 0) + payment.amount.amount;

                  //     const checkSummary = {
                  //       tax: { percentage: 0, netPrice: payment.tax?.amount || 0 },
                  //       discount: { percentage: 0, netPrice: 0 },
                  //       serviceCharges: { percentage: 0, netPrice: 0 },
                  //       deliveryFee: { percentage: 0, netPrice: payment.fee?.amount || 0 },
                  //       extraFee: [],
                  //       total: sum,
                  //       subtotal: payment.amount.amount,
                  //       minimumOrderValue: 0
                  //     } as CheckSummary;

                  //     patchState({cardInfo, checkSummary, tips});
                  //   } else {
                  //     patchState({cardInfo, tips});
                  //   }

                  //   this.store.dispatch(new SetSpinnerVisibility(false));
                  // })
               );
  }

  @Action(ClearOrderData)
  clearOrderData({  patchState  }: StateContext<OrderHistoryStateModel>) {
    patchState(createCopy(DAFAULT_ORDER_DATA) as OrderHistoryStateModel);
  }
}
