<div class="rs-venue-logo" [style.--size]="size">
  <img
    *ngIf="logo; else defaultIcon"
    [src]="logo.resolutions.low.url"
    [alt]="'images.venue-label' | translate"
    class="rs-venue-logo__image"
  />
</div>

<ng-template #defaultIcon>
  <mat-icon class="rs-venue-logo__icon">restaurant_menu</mat-icon>
</ng-template>
