import { PaymentCard } from '../enums/payment-card.enum';
import { PaymentCardMeasure } from '../models/card.model';

const MIN_CARD_LENGTH_VALUE = 13;
const MAX_CARD_LENGTH_VALUE = 19;

const MIN_DOWNPAYMENT_PART = 0.25;

/**
  * Main source for card's validation parameters:
  * https://en.wikipedia.org/wiki/Payment_card_number
  * Additional parameters were added from a large number of supporting articles and sources.
*/
const PAYMENT_SYSTEM_MEASURES: PaymentCardMeasure[] = [
  {
    type                : PaymentCard.VISA,
    cardNumberLength    : [ 13, 16 ],
    validationCodeLength: 3,
    ranges              : [
      { min: 4, max: 4 }
    ],
    exceptRanges        : []
  },
  {
    type                : PaymentCard.VISA_ELECTRON,
    cardNumberLength    : [ 16 ],
    validationCodeLength: 3,
    ranges              : [
      { min: 4026, max: 4026 },
      { min: 417500, max: 417500 },
      { min: 4508, max: 4508 },
      { min: 4844, max: 4844 },
      { min: 4913, max: 4913 },
      { min: 4917, max: 4917 }
    ],
    exceptRanges        : []
  },
  {
    type                : PaymentCard.MASTERCARD,
    cardNumberLength    : [ 16 ],
    validationCodeLength: 3,
    ranges              : [
      { min: 51, max: 55 },
      { min: 222100, max: 272099 }
    ],
    exceptRanges        : []
  },
  {
    type                : PaymentCard.AMEX,
    cardNumberLength    : [ 15 ],
    validationCodeLength: 4,
    ranges              : [
      { min: 34, max: 34 },
      { min: 37, max: 37 }
    ],
    exceptRanges        : []
  },
  {
    type                : PaymentCard.DINERS_CLUB_INTERNATIONALS,
    cardNumberLength    : [ 14, 15, 16, 17, 18, 19 ],
    validationCodeLength: 3,
    ranges              : [
      { min: 3095, max: 3095 },
      { min: 36, max: 36 },
      { min: 38, max: 39 }
    ],
    exceptRanges        : []
  },
  {
    type                : PaymentCard.DINERS_CLUB_USA_CANADA,
    cardNumberLength    : [ 16 ],
    validationCodeLength: 3,
    ranges              : [
      { min: 54, max: 55 }
    ],
    exceptRanges        : []
  },
  {
    type                : PaymentCard.DINERS_CLUB_CARTE_BLANCHE,
    cardNumberLength    : [ 14 ],
    validationCodeLength: 3,
    ranges              : [
      { min: 300, max: 305 }
    ],
    exceptRanges        : []
  },
  {
    type                : PaymentCard.DISCOVER,
    cardNumberLength    : [ 16, 17, 18, 19 ],
    validationCodeLength: 3,
    ranges              :[
      { min: 300000, max: 306000 },
      { min: 309500, max: 309600 },
      { min: 352800, max: 359000 },
      { min: 36, max: 36 },
      { min: 38, max: 38 },
      { min: 39, max: 39 },
      { min: 6011, max: 6011 },
      { min: 622126, max: 622925 },
      { min: 628200, max: 628899 },
      { min: 644, max: 649 },
      { min: 65, max: 65 }
    ],
    exceptRanges        : []
  },
  {
    type                : PaymentCard.JCB,
    cardNumberLength    : [ 15, 16, 17, 18, 19 ],
    validationCodeLength: 3,
    ranges              : [
      { min: 1800, max: 1800 },
      { min: 2131, max: 2131 },
      { min: 3088, max: 3094 },
      { min: 3096, max: 3102 },
      { min: 3112, max: 3120 },
      { min: 3158, max: 3159 },
      { min: 3337, max: 3349 },
      { min: 3528, max: 3589 }
    ],
    exceptRanges        : [
      { min: 352800, max: 358999 }
    ]
  },
  {
    type                : PaymentCard.MAESTRO,
    cardNumberLength    : [ 16, 17, 18, 19 ],
    validationCodeLength: 3,
    ranges              : [
      { min: 5018, max: 5018 },
      { min: 5020, max: 5020 },
      { min: 5038, max: 5038 },
      { min: 5893, max: 5893 },
      { min: 6304, max: 6304 },
      { min: 6759, max: 6759 },
      { min: 6761, max: 6763 }
    ],
    exceptRanges        : []
  },
  {
    type                : PaymentCard.UNKNOWN,
    cardNumberLength    : [ 13, 14, 15, 16, 17, 18, 19 ],
    validationCodeLength: 3,
    ranges              : [],
    exceptRanges        : []
  }
];

const CVV_MASK = {
  3: [/\d/, /\d/, /\d/],
  4: [/\d/, /\d/, /\d/, /\d/]
};

const PAYMENT_SYSTEM_TITLES = {
  [PaymentCard.VISA]                      : 'Visa',
  [PaymentCard.VISA_ELECTRON]             : 'Visa Electron',
  [PaymentCard.MASTERCARD]                : 'Mastercard',
  [PaymentCard.AMEX]                      : 'AMEX',
  [PaymentCard.DISCOVER]                  : 'Discover',
  [PaymentCard.DINERS_CLUB_INTERNATIONALS]: 'Diners Club - International',
  [PaymentCard.DINERS_CLUB_USA_CANADA]    : 'Diners Club - USA & Canada',
  [PaymentCard.DINERS_CLUB_CARTE_BLANCHE] : 'Diners Club - Carte Blanche',
  [PaymentCard.JCB]                       : 'JCB',
  [PaymentCard.MAESTRO]                   : 'Maestro',
  [PaymentCard.UNKNOWN]                   : 'Unknown card type'
};

const PAYMENT_IMAGE = {
  rootSVG                                 : 'assets/icons/card/',
  typeSVG                                 : '.svg',
  [PaymentCard.VISA]                      : 'visa',
  [PaymentCard.VISA_ELECTRON]             : 'visa-electron',
  [PaymentCard.MASTERCARD]                : 'mastercard',
  [PaymentCard.AMEX]                      : 'amex',
  [PaymentCard.DISCOVER]                  : 'discover',
  [PaymentCard.DINERS_CLUB_INTERNATIONALS]: 'diners',
  [PaymentCard.DINERS_CLUB_USA_CANADA]    : 'diners',
  [PaymentCard.DINERS_CLUB_CARTE_BLANCHE] : 'diners',
  [PaymentCard.JCB]                       : 'jcb',
  [PaymentCard.MAESTRO]                   : 'maestro',
  [PaymentCard.UNKNOWN]                   : 'default'
};

export {
  MIN_CARD_LENGTH_VALUE,
  MAX_CARD_LENGTH_VALUE,
  PAYMENT_SYSTEM_MEASURES,
  CVV_MASK,
  PAYMENT_SYSTEM_TITLES,
  PAYMENT_IMAGE,
  MIN_DOWNPAYMENT_PART
};
