import { CardForm } from '../shared/models/card.model';

export class SetDownpaymentAmount {
  static readonly type = '[Checkout] Set Downpayment Amount';

  constructor(public amount: number) {}
}

export class ResetBillingAddressValidity {
  static readonly type = '[Checkout] reset billing address';
}

export class ResetCheckoutState {
  static readonly type = '[Checkout] reset state';
}
export class Checkout {
  static readonly type = '[Checkout] start';
}

export class ValidateAndSaveBillingCard {
  static readonly type = '[Checkout] validate and save billing card';

  constructor(public card: CardForm) {}
}
