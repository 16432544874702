import { SizesAndPrices } from '../models/item';

export const getDevicePriceInCents =
  (sizesAndPrices: SizesAndPrices[] = [{menuAttributes: []}] as SizesAndPrices[]): number => {
    const menuAttributes = (sizesAndPrices?.[0]?.menuAttributes ) ?? [];
    const menuAttribute = menuAttributes?.[0] || {price: 0};
    const {price} = menuAttribute;

    return price;
  };
