import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UserProfileAddress } from 'src/app/shared/models/address.model';

@Component({
  selector: 'rs-address-item',
  templateUrl: './address-item.component.html',
  styleUrls: ['./address-item.component.scss']
})
export class AddressItemComponent {
  @Input() set index(i: number) {
    this.addressIndex = i;
  };
  @Input() set addressValue(address: UserProfileAddress) {
    this.address = address;
  };
  @Input() set isSelectedAddressId(isSelected: boolean) {
    this.isSelected = isSelected;
  };
  @Input() showActionButtons: boolean = true;

  @Output() select = new EventEmitter<number>();
  @Output() edit = new EventEmitter<number>();
  @Output() delete = new EventEmitter<number>();

  public addressIndex: number;
  public address: UserProfileAddress;
  public isSelected: boolean;

  public selectAddress(): void {
    this.select.emit(this.addressIndex);
  }

  public editAddress(): void {
    this.edit.emit(this.addressIndex);
  }

  public deleteAddress(): void {
    this.delete.emit(this.addressIndex);
  }

}
