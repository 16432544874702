import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';

import { SetSideVisibility } from 'src/app/ngxs/app.actions';
import { SelectUserVenue } from 'src/app/ngxs/user.actions';
import { UserProfileState } from 'src/app/ngxs/user.state';
import { INPUT_DEBOUNCE_TIME } from 'src/app/shared/constants/common';
import { Venue } from 'src/app/shared/models/venue.model';

@Component({
  selector: 'rs-user-venues',
  templateUrl: './user-venues.component.html',
  styleUrls: ['./user-venues.component.scss'],
})
export class UserVenuesComponent implements OnInit {
  @Select((state) => state.user.venues)
  venues$: Observable<Venue[]>;

  @Select(UserProfileState.selectedVenueId)
  selectedVenueId$: Observable<string>;

  public queryControl = new FormControl('');
  public filteredVenues$: Observable<Venue[]>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.setFilteredVenuesValue();
  }

  public selectVenue(venue: Venue): void {
    this.store.dispatch([
      new SelectUserVenue(venue),
      new SetSideVisibility(false),
    ]);
  }

  private setFilteredVenuesValue(): void {
    this.filteredVenues$ = this.queryControl.valueChanges.pipe(
      debounceTime(INPUT_DEBOUNCE_TIME),
      startWith(''),
      map((query) => query?.trim().toLowerCase()),
      switchMap((query) =>
        !query
          ? this.venues$
          : this.venues$.pipe(
              map((venues) =>
                venues.filter(
                  ({ name, address }) =>
                    name.toLowerCase().includes(query) ||
                    address.region?.toLowerCase().includes(query) ||
                    address.state?.toLowerCase().includes(query) ||
                    address.city?.toLowerCase().includes(query)
                )
              )
            )
      )
    );
  }
}
