import { Venue } from '../shared/models/venue.model';

export class GetUserProfileData {
  static readonly type = '[User Profile Data] Get';
}

export class ClearUserData {
  static readonly type = '[User Profile Data] Clear';
}

export class GetProfileAddresses {
  static readonly type = '[User Addresses] Get';
}

export class GetUserVenues {
  static readonly type = '[User Venues] Get';
}

export class SelectUserVenue {
  static readonly type = '[User Venue] Select';

  constructor(public selectedVenue: Venue) {}
}
