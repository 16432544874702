export enum PaymentCard {
  VISA                       = 'VISA',
  VISA_ELECTRON              = 'VISA_ELECTRON',
  MASTERCARD                 = 'MASTERCARD',
  AMEX                       = 'AMEX',
  DISCOVER                   = 'DISCOVER',
  DINERS_CLUB_INTERNATIONALS = 'DINERS_CLUB_INTERNATIONALS',
  DINERS_CLUB_USA_CANADA     = 'DINERS_CLUB_USA_CANADA',
  DINERS_CLUB_CARTE_BLANCHE  = 'DINERS_CLUB_CARTE_BLANCHE',
  JCB                        = 'JCB',
  MAESTRO                    = 'MAESTRO',
  UNKNOWN                    = 'UNKNOWN'
};
