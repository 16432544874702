import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../../shared/guards/auth.guard';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { LayoutComponent } from './layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'marketplace',
        loadChildren: () =>
          import('../marketplace/marketplace.module').then(
            (m) => m.MarketplaceModule
          ),
      },

      {
        path: 'my-devices',
        loadChildren: () =>
          import('../my-devices/my-devices.module').then(
            (m) => m.MyDevicesModule
          ),
      },

      {
        path: 'orders-history',
        loadChildren: () =>
          import('../orders-history/orders-history.module').then(
            (m) => m.OrdersHistoryModule
          ),
      },

      // TODO: add handling for http response errors with 'no access' status
      {
        path: 'no-access',
        component: NoAccessComponent
      }
    ],
  },
  { path: '**', redirectTo: '/marketplace' },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
