<div class="rs-tabs d-flex align-items-center">
  <div class="rs-tabs__tab" *ngFor="let tab of tabs; let i = index">
    <a
      class="rs-tabs__tab-item"
      (click)="onTabClick(tab.link)"
      [ngClass]="{ 'rs-tabs__tab-item--selected': (selectedTab$ | async) === i }"
    >
      {{ tab.title | translate }}
    </a>
  </div>

  <div class="rs-shop-cart d-flex">
    <ng-container *ngIf="(cartItems$ | async); let cartItems">
      <button
        class="rs-shop-cart__open-button"
        [matBadgeHidden]="cartItems.length === 0"
        [matBadge]="cartItems.length"
        [disabled]="cartItems.length === 0"
        matBadgeColor="primary"
        (click)="openCart()"
      >
          <img src="../../../assets/icons/icon-cart.svg"/>
      </button>
    </ng-container>
  </div>
</div>
