import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../models/address.model';

@Pipe({name: 'fullAddress'})
export class ShowFullAddressPipe implements PipeTransform {
  transform(address: Address): string {
    let fullAddress = '';
    if (address) {
      let fullAddressArray = [address.address1 || '', address.city || '', address.state || '', address.zipcode || ''];
      fullAddressArray = fullAddressArray.filter(element => !!element);
      if (fullAddressArray.length) {
        fullAddress = fullAddressArray.join(', ');
      }
    }
    
    return fullAddress;
  }
}
