import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CartItem } from 'src/app/modules/cart/cart.models';
import { SizesAndPrices } from '../models/item';

export const generateQueriesBasedOnQuantity = <T>(
  quantity: number,
  query: Observable<T>
): Observable<T>[] => (quantity > 0 ? new Array(quantity).fill(query) : []);

export const sequential = <T>(
  ...rest: Observable<T>[]
): ((source: Observable<T>) => Observable<T>) => {
  const [request, ...otherRequests] = rest;

  if (!request) {
    return (source) => source;
  }

  return (source: Observable<T>) =>
    source.pipe(
      switchMap(() => request),
      sequential(...otherRequests)
    );
};

export const getDeviceMetadata = (
  sizesAndPrices: SizesAndPrices[] = [
    { menuAttributes: [] },
  ] as SizesAndPrices[]
) => {
  const sizeAndPrice = sizesAndPrices?.[0] ?? ({} as SizesAndPrices);
  const menuAttribute = sizeAndPrice.menuAttributes?.[0];
  return {
    menuId: menuAttribute?.menuId,
    sectionId: menuAttribute?.menuSectionId,
    sizeId: sizeAndPrice.id,
  };
};

export const getLastItemInCart = (cartItems: CartItem[] = []) => {
  const getLastIndex = cartItems.length ? cartItems.length - 1 : 0;
  return cartItems[getLastIndex];
};

export const filterCartItemsById = (
  cartItems: CartItem[],
  deviceId: string
) => {
  return (cartItems ?? []).filter(({ itemId }) => itemId === deviceId);
};

export const getItemsForDelete = (
  cartItems: CartItem[],
  deviceId: string,
  quantity: number
) => {
  return filterCartItemsById(cartItems, deviceId).slice(-quantity);
};

export const getNotificationItem = (photo: string, cartItems: CartItem[]) => {
  return {
    photo,
    quantity: cartItems.length,
    name: cartItems[0]?.name ?? '',
  };
};
