import { CalculatorData } from '../modules/marketplace/models/device.model';

export class GetDevicesData {
  static readonly type = '[Devices Data] Get';
}

export class GetRecommendDevices {
  static readonly type = '[Devices] Find devices';

  constructor(public payload: CalculatorData) {}
}

export class GetDetailedDeviceInformation {
  static readonly type = '[Device] Get detailed device information';

  constructor(public itemId: string) {}
}

export class ChangeRecommendedDeviceCount {
  static readonly type = '[Device] Increase Recommended Device Count';

  constructor(public itemId: string, public count: number) {}
}
