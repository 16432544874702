// TODO: add real data
export const BACKOFFICE_DATA = [
  {
    title: 'titles.my-profile',
    iconUrl: 'assets/icons/user.svg',
  },
  {
    title: 'titles.merchant-system',
    iconUrl: 'assets/icons/user.svg',
  },
  {
    title: 'titles.affiliate-program',
    iconUrl: 'assets/icons/user.svg',
  },
  {
    title: 'titles.my-earnings',
    iconUrl: 'assets/icons/user.svg',
  },
  {
    title: 'titles.rockspoon-website',
    iconUrl: 'assets/icons/user.svg',
  },
  {
    title: 'titles.devices-marketplace',
    iconUrl: 'assets/icons/user.svg',
  },
];
