<mat-icon
  (click)="open($event)"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  [class]="iconClass"
  class="rs-clickable-icon"
>
  {{ icon || "apps" }}
</mat-icon>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  (backdropClick)="hide()"
>
  <div @fadeIn class="mat-elevation-z4 rs-menu-wrapper">
    <ng-content></ng-content>
  </div>
</ng-template>
