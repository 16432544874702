import { Component, OnInit } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Navigate } from '@ngxs/router-plugin';

import { SetSideVisibility } from '../../../ngxs/app.actions';
import { CartItemWithQuantity, CartValueCheck } from '../cart.models';
import { CartState } from 'src/app/ngxs/cart.state';
import { PAGES } from 'src/app/shared/constants/pages';

@UntilDestroy()
@Component({
             selector   : 'rs-cart',
             templateUrl: './cart.component.html',
             styleUrls  : ['./cart.component.scss']
           })
export class CartComponent implements OnInit {
  @Select(CartState.itemsWithQuantity) items$: Observable<CartItemWithQuantity[]>;
  @Select(CartState.badgeNumber) itemsAmount$: Observable<CartItemWithQuantity[]>;
  @Select(CartState.check) check$: Observable<CartValueCheck>;
  @Select(CartState.disableCartItems) disabled$: Observable<boolean>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.select(CartState.items).pipe(
      map((items) => items?.length ?? 0),
      filter((length) => length === 0),
      untilDestroyed(this)
    ).subscribe(() =>  this.close());
  }

  public close(): void {
    this.store.dispatch(new SetSideVisibility(false));
  }

  public goToCheckout(): void {
    this.store.dispatch([new SetSideVisibility(false), new Navigate([PAGES.CHECKOUT])]);
  }
}
