<div class="rs-choose-default">
  <div class="rs-choose-default__title text-center">{{ 'common.choose-a-new-default-address' | translate }}</div>
  <div class="rs-choose-default__content">
    <div class="rs-choose-default__content-item" *ngFor="let address of addresses, let i = index">
      <rs-address-item
        [index]="i"
        [addressValue]="address"
        [isSelectedAddressId]="selectedAddressIndex === i"
        [showActionButtons]="false"
        (select)="reactOnSelectAddress($event)">
      </rs-address-item>
    </div>
  </div>
  <div class="rs-choose-default__actions text-right">
    <button
      class="rs-app__blue-button"
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="!selectedAddress"
    >{{ 'buttons.save' | translate }}</button>
  </div>
</div>