import { Validators } from '@angular/forms';
import { CalculatorStep } from '../models/device.model';

export const TOGGLE_ITEMS = [
  {
    title: 'common.yes',
    value: true,
  },
  {
    title: 'common.no',
    value: false,
  },
];

const numberValidators = [Validators.required, Validators.min(0), Validators.pattern('^[0-9]*$')];
const toggleValidators = [Validators.required];

export const CALCULATOR_STEPS: CalculatorStep[] = [
  {
    groupName: 'spaceDetails',
    label    : 'calculator.space-details-label',
    title    : 'calculator.space-details-title',
    controls : [
      {
        label     : 'calculator.kitchens-control',
        name      : 'kitchens',
        validators: numberValidators
      },
      {
        label     : 'calculator.stations-control',
        name      : 'stationsPerKitchen',
        validators: numberValidators
      },
      {
        label     : 'calculator.bars-control',
        name      : 'bars',
        validators: numberValidators
      },
      {
        label     : 'calculator.floors-control',
        name      : 'floors',
        validators: numberValidators
      },
      {
        label     : 'calculator.tables-control',
        name      : 'tables',
        validators: numberValidators
      },
      {
        label     : 'calculator.seats-control',
        name      : 'seats',
        validators: numberValidators
      },
    ],
  },
  {
    groupName: 'systemDetails',
    label    : 'calculator.system-details-label',
    title    : 'calculator.system-details-title',
    controls : [
      {
        label     : 'calculator.fixed-pos-control',
        name      : 'fixedPos',
        validators: numberValidators
      },
      {
        label     : 'calculator.printer-per-station-control',
        name      : 'printerPerStation',
        isToggle  : true,
        validators: toggleValidators
      },
    ],
  },
  {
    groupName: 'staffDetails',
    label    : 'calculator.staff-details-label',
    title    : 'calculator.staff-details-title',
    controls : [
      {
        label     : 'calculator.hosts-control',
        name      : 'hosts',
        isToggle  : true,
        validators: toggleValidators
      },
      {
        label     : 'calculator.runners-control',
        name      : 'runners',
        validators: numberValidators
      },
      {
        label     : 'calculator.waiters-per-shift-control',
        name      : 'waitersPerShift',
        validators: numberValidators
      },
      {
        label     : 'calculator.waiters-over-45-control',
        name      : 'waitersOver45',
        validators: numberValidators
      },
    ],
  },
];
