<section class="user-venues">
  <mat-form-field appearance="outline" class="user-venues__search-input">
    <mat-label>{{ "placeholders.search-venue" | translate }}</mat-label>
    <input type="text" matInput [formControl]="queryControl" />
    <mat-icon matSuffix color="primary">search</mat-icon>
  </mat-form-field>

  <div class="user-venues__list">
    <div
      *ngFor="let venue of filteredVenues$ | async"
      class="user-venues__item"
      [class.selected]="(selectedVenueId$ | async) === venue.id"
      (click)="selectVenue(venue)"
    >
      <rs-venue-logo size="80px" [logo]="venue.logo"></rs-venue-logo>
      <p class="user-venues__name">
        {{ venue.name }}
      </p>
      <p class="user-venues__address">{{ venue.address.address1 }}</p>
      <p class="user-venues__location">
        {{ venue.address.city }}{{ venue.address.city ? "," : "" }}
        {{ venue.address.country }}
      </p>
    </div>
  </div>
</section>
