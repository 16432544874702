enum CurrencyType {
  USD = 'USD',
  EUR = 'EUR',
  BRL = 'BRL',
  CLP = 'CLP',
  JOD = 'JOD'
};

enum CurrencySymbol {
  USD = '$',
  EUR = '€',
  BRL = 'R$',
  CLP = '$',
  JOD = 'د.أ',
  '$' = '$',
  '€' = '€',
  'R$' = 'R$',
  'د.أ' = 'د.أ'
};

export {
  CurrencyType,
  CurrencySymbol
};

