import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rs-item-quantity',
  templateUrl: './item-quantity.component.html',
  styleUrls: ['./item-quantity.component.scss']
})
export class ItemQuantityComponent implements OnInit {
  @Input() quantity: number;
  @Input() changeable: boolean;
  @Input() maxValue: number;
  @Input() disabled: boolean;

  @Output() increased: EventEmitter<number> = new EventEmitter();
  @Output() decreased: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public increase(): void {
    this.increased.next(1); // TODO: 1 for now, fot next we need to handle multiple clicks
  }

  public decrease(): void {
    this.decreased.next(1); // TODO: 1 for now, fot next we need to handle multiple clicks
  }
}
