<div class="rs-breadcrumb" *ngIf="breadcrumbs?.length">
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
    <a
      class="rs-breadcrumb__link"
      [style.color]="color"
      (click)="onLinkClick(breadcrumb.link, i)"
      >{{ breadcrumb.title | translate }}</a
    >
    <mat-icon
      aria-hidden="false"
      aria-label="icon"
      [style.color]="color"
      *ngIf="i !== breadcrumbs.length - 1"
    >
      keyboard_arrow_right
    </mat-icon>
  </ng-container>
</div>
